import { Box, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { CHART_COLORS, DATASET_STATES } from "../../constants";
import NumberBox from "../NumberBox";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import { Link } from "react-router-dom";
import StorageIcon from "@material-ui/icons/Storage";
import ImageIcon from "@material-ui/icons/Image";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import NearMeIcon from "@material-ui/icons/NearMe";
import { useTranslation } from "react-i18next";

function TrainingStats() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [{ data: statistics }] = useAxios("/datasets/statistics");

  useEffect(() => {
    if (!statistics) return;
    setData(statistics);
  }, [statistics]);

  return (
    <NumberBox
      title={t("Detection Training Stats")}
      itemPerRow={3}
      items={[
        {
          label: t("Total Datasets"),
          value: data && data.totalDatasets,
          color: CHART_COLORS.green,
          icon: <StorageIcon />
        },
        {
          label: t("Before Training"),
          value:
            data && data.totalDatasets - (data[DATASET_STATES.finish] ?? 0),
          color: CHART_COLORS.orange,
          icon: <FastRewindIcon />
        },
        {
          label: t("After Training"),
          value: data && (data[DATASET_STATES.finish] ?? 0),
          color: CHART_COLORS.purple,
          icon: <FastForwardIcon />
        },
        {
          label: t("Total # Images"),
          value: data && data.totalImages,
          color: CHART_COLORS.green,
          icon: <ImageIcon />
        },
        {
          label: t("Last Trained"),
          value:
            (data &&
              data.lastTrained &&
              `${new Date(data.lastTrained).toLocaleTimeString()}\n${new Date(
                data.lastTrained
              ).toLocaleDateString()}`) ||
            "(?)",
          color: CHART_COLORS.yellow,
          icon: <NearMeIcon />
        },
        {
          label: t("Go To Training"),
          component: (
            <Box
              component={Link}
              to="/training"
              style={{ textDecoration: "none" }}
              color={CHART_COLORS.blue}
              bgcolor={CHART_COLORS.blue + "20"}
              display="flex"
              p={0.5}
              my={1}
              textAlign="center"
              borderRadius={32}
              justifyContent="flex-start"
              alignItems="center"
            >
              <DonutLargeIcon color="inherit" />
              <Typography
                variant="button"
                color="inherit"
                align="left"
                style={{ lineHeight: 1.5, marginLeft: 6 }}
              >
                {t("Go To Training")}
              </Typography>
            </Box>
          )
        }
      ]}
    />
  );
}

export default TrainingStats;
