import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Annotator from "./Annotator";
import { IconButton } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import MainLayout from "./MainLayout";
import ControlPanel from "./training/ControlPanel";
import Dashboard from "./dashboard/Dashboard";
import { AppContext } from "./AppContext";
import { API_BASE } from "../constants";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import { EventSourcePolyfill } from "event-source-polyfill";
import AccountList from "./accounts/AccountList";
import TestingPage from "./testing/TestingPage";
import ApiManagerPage from "./api-manager/ApiManagerPage";
import DatasetPage from "./datasets/DatasetPage";
import TrainingJobResult from "./training/TrainingJobResult";

function App() {
  const notiRef = useRef();
  const [token, setToken] = useState();

  const handleDismissSnack = key => () => {
    notiRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    const encodedToken = localStorage.getItem("M4xSNAPMyi");
    if (!encodedToken) return;
    try {
      const rawToken = atob(encodedToken);
      setToken(rawToken);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (!token) return;
    if (token === "none") {
      localStorage.removeItem("M4xSNAPMyi");
      return;
    }
    const encodedToken = btoa(token);
    localStorage.setItem("M4xSNAPMyi", encodedToken);
  }, [token]);

  useEffect(() => {
    if (!token) return;
    const authInterceptor = axios.interceptors.request.use(
      async config => {
        if (token) {
          config.headers = {
            authorization: `Bearer ${token}`
          };
        }
        return config;
      },
      error => Promise.reject(error)
    );
    return () => {
      axios.interceptors.request.eject(authInterceptor);
    };
  }, [token]);

  useEffect(() => {
    const unauthInterceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.response && error.response.status === 401) {
          setToken("none");
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(unauthInterceptor);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        eventSource: new EventSourcePolyfill(`${API_BASE}/sse`, {
          headers: {
            authorization: `Bearer ${token}`
          }
        }),
        siEventSource: new EventSourcePolyfill(`${API_BASE}/si`, {
          headers: {
            authorization: `Bearer ${token}`
          }
        }),
        isAuth: !!token && token !== "none",
        setToken
      }}
    >
      <SnackbarProvider
        ref={notiRef}
        maxSnack={3}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        action={key => (
          <IconButton onClick={handleDismissSnack(key)} color="inherit">
            <CloseIcon color="inherit" />
          </IconButton>
        )}
      >
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route>
              <MainLayout>
                <Switch>
                  <PrivateRoute path="/annotate/:id">
                    <Annotator />
                  </PrivateRoute>
                  <PrivateRoute path="/training/:id">
                    <TrainingJobResult />
                  </PrivateRoute>
                  <PrivateRoute path="/training">
                    <ControlPanel />
                  </PrivateRoute>
                  <PrivateRoute path="/testing">
                    <TestingPage />
                  </PrivateRoute>
                  <PrivateRoute path="/api-manager">
                    <ApiManagerPage />
                  </PrivateRoute>
                  <PrivateRoute path="/datasets">
                    <DatasetPage />
                  </PrivateRoute>
                  <PrivateRoute path="/accounts">
                    <AccountList />
                  </PrivateRoute>
                  <PrivateRoute path="/">
                    <Dashboard />
                  </PrivateRoute>
                </Switch>
              </MainLayout>
            </Route>
          </Switch>
        </Router>
      </SnackbarProvider>
    </AppContext.Provider>
  );
}

export default App;
