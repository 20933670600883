import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core";
import { formatBytes } from "../../helpers/convertByte";
import CheckIcon from "@material-ui/icons/Check";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import { green } from "@material-ui/core/colors";

function ImageItem({ selected, img, onSelected, startDetect, onData }) {
  const [{ loading }, detect] = useAxios(
    { url: "/detect", method: "post" },
    { manual: true }
  );
  const [finished, setFinished] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (!startDetect || loading) return;
    if (startDetect && finished) {
      onData(data);
      return;
    }
    const formData = new FormData();
    formData.append("file", img.value);
    detect({ data: formData }).then(res => {
      onData(res.data);
      setData(res.data);
      setFinished(res && res.status === 201);
    });
  }, [startDetect, img, finished, onData, detect, loading, data]);

  let avgProb =
    data &&
    data.results.reduce((prev, { prob }) => ((prev ?? prob) + prob) / 2, null);
  avgProb = avgProb && (avgProb * 100).toFixed(2);

  return (
    <ListItem selected={selected}>
      <ListItemAvatar style={{ position: "relative" }}>
        {finished ? (
          <Avatar style={{ backgroundColor: green[500] }}>
            <CheckIcon />
          </Avatar>
        ) : (
          <>
            <Avatar alt="image" src={img.url} />
            {loading && (
              <CircularProgress
                disableShrink
                size={47}
                style={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -23,
                  marginLeft: -32
                }}
              />
            )}
          </>
        )}
      </ListItemAvatar>
      <ListItemText
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}
        primary={`${img.name} (${formatBytes(img.size)})`}
        secondary={
          data &&
          `${data.duration}ms | ${avgProb ?? 100}% ${
            data.results?.length ? "blueprint" : "non-blueprint"
          }`
        }
      />
      {finished && (
        <ListItemSecondaryAction>
          <IconButton onClick={() => onSelected({ ...data, ...img })}>
            <ArrowIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

export default ImageItem;
