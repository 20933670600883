import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function ProcessError({ error }) {
  const { t } = useTranslation();

  return (
    <Box height="100%" width="100%" textAlign="center">
      <ErrorOutlineIcon fontSize="large" color="error" />
      <Typography color="error">{t('Error:')} {error.message}</Typography>
    </Box>
  );
}

export default ProcessError;
