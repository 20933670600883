import { Box, Typography } from "@material-ui/core";
import React from "react";

function NumberBox({ title, value, color }) {
  return (
    <Box color={color}>
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
      <Typography color="inherit">
        <strong>{value || "(?)"}</strong>
      </Typography>
    </Box>
  );
}

export default NumberBox;
