import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "../../constants";

function ProbabilityDistributionChart({ data }) {
  const { t } = useTranslation();
  const labels = [t("Over 90%"), t("80-90%"), t("60-80%"), t("Under 60%")];
  const datasets = [
    {
      label: "Dataset 1",
      data,
      backgroundColor: [
        CHART_COLORS.green,
        CHART_COLORS.blue,
        CHART_COLORS.orange,
        CHART_COLORS.red
      ]
    }
  ];
  return (
    <Doughnut
      data={{ labels, datasets }}
      height={"100%"}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }}
    />
  );
}

export default ProbabilityDistributionChart;
