import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { configure } from "axios-hooks";
import axios from "axios";
import { API_BASE } from "./constants";
import './i18n';
import { Box, CircularProgress } from "@material-ui/core";

axios.defaults.baseURL = API_BASE

configure({ axios, cache: false });

ReactDOM.render(
  <Suspense fallback={
    <Box pt="300px" textAlign="center">
      <CircularProgress />
    </Box>
  }>
    <App />
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
