import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import ImageTestList from "./ImageTestList";
import TestResultStats from "./TestResultStats";
import TestResult from "./TestResult";
import Improvement from "./Improvement";
import UploadImage from "./UploadImage";
import useAxios from "axios-hooks";

function TestingPage() {
  const [images, setImages] = useState([]);
  const [currentResult, setCurrentResult] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [testStat, setTestStat] = useState(null);

  const [{ data: listData }, refetch] = useAxios({
    url: "/datasets",
    params: {
      skip: 0,
      limit: 0,
      type: "from_test"
    }
  });

  const onChangeImages = event => {
    let tmp = [];
    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        const url = URL.createObjectURL(event.target.files[i]);
        tmp.push({
          name: event.target.files[i].name,
          size: event.target.files[i].size,
          value: event.target.files[i],
          url
        });
      }
      setImages([...tmp]);
      setCurrentResult();
    }
  };

  const onChangeCurrentResult = value => setCurrentResult(value);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <UploadImage onChangeImages={onChangeImages} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TestResultStats testStat={testStat} images={images} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Improvement
            currentResult={currentResult}
            selectedDataset={selectedDataset}
            handleChangeDataset={val => setSelectedDataset(val)}
            listData={listData}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ImageTestList
            images={images}
            onChangeCurrentResult={onChangeCurrentResult}
            onStatsChange={setTestStat}
          />
        </Grid>

        <Grid item xs={12} lg={8}>
          <TestResult
            currentResult={currentResult}
            selectedDataset={selectedDataset}
            refetchDataset={refetch}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default TestingPage;
