import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

/**
 *
 * @param {object} props Component properties
 * @param {string} props.title Box title
 * @param {number} props.itemPerRow Number of items on a row
 * @param {[{ 
 *  label: string,
 *  value: string | number, 
 *  icon: React.ReactElement, 
 *  color: string, 
 *  component: React.ReactElement 
 * }]} props.items List of items, color must be HEX color, if `component` is set, others will be ignored
 */
function NumberBox({ title, items, itemPerRow = 2 }) {
  return (
    <Paper component={Box} p={2} height="100%">
      <Box mb={1}>
        <Typography
          style={{
            textTransform: "uppercase",
            fontWeight: "bold"
          }}
        >
          {title}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {items &&
          items.map(({ label, value, icon, color, component }) => (
            <Grid item xs={12 / itemPerRow} key={label}>
              {component}
              {!component && (
                <Box
                  color={color}
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                >
                  {icon && (
                    <Box
                      mr={1}
                      height={30}
                      width={30}
                      minWidth={30}
                      bgcolor={color + "22"}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="50%"
                    >
                      {icon}
                    </Box>
                  )}
                  <Box>
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      display="block"
                    >
                      {label}
                    </Typography>
                    <Typography color="inherit">
                      <strong>{value ?? "(?)"}</strong>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
}

export default NumberBox;
