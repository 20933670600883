import React from "react";
import ReactImageAnnotate from "@ngoan98tv/react-image-annotate";
import { useHistory, useParams } from "react-router-dom";
import useAxios from "axios-hooks";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import {
  Box,
  Button,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { DATASET_STATES } from "../constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function Annotator() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [imageData, setImageData] = useState();
  const [clsList, setClsList] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [{ data, loading, error }] = useAxios(`/datasets/${id}/images`);
  const [
    { data: datasetData, loading: datasetLoading, error: datasetError }
  ] = useAxios(`/datasets/${id}`);
  const [{ data: clsData, loading: clsLoading, error: clsError }] = useAxios(
    "/classifications"
  );
  const [
    { data: updateData, loading: updating, error: updateError },
    updateImages
  ] = useAxios(
    {
      url: `/datasets/${id}/images`,
      method: "PATCH"
    },
    { manual: true }
  );

  const [, updateDataset] = useAxios(
    {
      url: `/datasets/${id}`,
      method: "PATCH"
    },
    { manual: true }
  );

  useEffect(() => {
    updateDataset({ data: { state: DATASET_STATES.annotating } });
  }, [updateDataset]);

  useEffect(
    () => {
      if (updating) {
        enqueueSnackbar(t("Saving image annotations"), { variant: "info" });
      }
    },
    // eslint-disable-next-line
    [updating, enqueueSnackbar]
  );

  useEffect(
    () => {
      if (updateError) {
        enqueueSnackbar(`${t("Failed to save")} ${updateError.message}`, {
          variant: "error"
        });
      }
    },
    // eslint-disable-next-line
    [updateError, enqueueSnackbar]
  );

  useEffect(
    () => {
      if (updateData) {
        enqueueSnackbar(t(`Saved successfully`), {
          variant: "success"
        });
        history.goBack();
      }
    },
    // eslint-disable-next-line
    [updateData, history, enqueueSnackbar]
  );

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleExit = ({ images, regionClsList }) => {
    setImageData(images);
    setClsList(regionClsList);
    setOpenDialog(true);
  };

  const handleSaveData = finish => () => {
    updateDataset({
      data: {
        state: finish ? DATASET_STATES.annotated : DATASET_STATES.annotating,
        cls: clsList
      }
    }).then(res => {
      if (res.status === 200) {
        updateImages({ data: imageData }).then(res0 => {
          if (res0.status !== 200) {
            window.alert(
              `${res0.status}:${res0.statusText}\n${JSON.stringify(
                res0.data || {}
              )}`
            );
          }
        });
      } else {
        window.alert(
          `${res.status}:${res.statusText}\n${JSON.stringify(res.data || {})}`
        );
      }
    });
  };

  if (loading || datasetLoading || clsLoading) return <p>Loading...</p>;
  if (error || datasetError || clsError)
    return (
      <p>
        Error! {error?.message} {datasetError?.message} {clsError?.message}
      </p>
    );

  let clsArr = clsData?.map(({ name }) => name) ?? [];
  clsArr = new Set([...clsArr, ...(datasetData?.cls ?? [])]);
  clsArr = Array.from(clsArr);

  return (
    <Box m={-3} height="calc(100vh - 80px)">
      <ReactImageAnnotate
        labelImages
        regionClsList={clsArr}
        enabledTools={["select", "create-box"]}
        onExit={handleExit}
        images={data}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{t("Are you finished annotating?")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('If not, "Save draft" to continue later.')} <br />
            {t('Choose "Finish" to process to the next step.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveData(false)}
            color="default"
            disabled={updating}
          >
            {t("Save Draft")}
          </Button>
          <Button
            onClick={handleSaveData(true)}
            color="primary"
            disabled={updating}
          >
            {t("Finish")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Annotator;
