import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import DatasetCreate from "./DatasetCreate";
import ProcessPanel from "./ProcessPanel";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import { DATASET_STATES } from "../../constants";
import TrainingJobDetail from "./TrainingJobDetail";
import ModelList from "./ModelList";

function ControlPanel() {
  const [list, setList] = useState([]);
  const [job, setJob] = useState();
  const [{ data: listData, loading: listLoading, error: listError }] = useAxios(
    {
      url: "/datasets",
      params: {
        skip: 0,
        limit: 0,
        states: Object.values(DATASET_STATES).filter(
          val => val !== DATASET_STATES.finish
        ),
        type: "wip"
      }
    }
  );
  const [
    { data: jobData, loading: jobLoading, error: jobError },
    refetchJob
  ] = useAxios({
    url: "/training-jobs",
    params: {
      skip: 0,
      limit: 1
    }
  });

  useEffect(() => {
    if (listData) setList(listData);
  }, [listData]);

  useEffect(() => {
    if (jobData && jobData.length > 0) setJob(jobData[0]);
  }, [jobData]);

  const handleNewData = newData => {
    setList(prev => [newData, ...prev]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={4}>
        <DatasetCreate onNewData={handleNewData} />
        <Box p={1} />
        <ProcessPanel
          list={list}
          isLoading={listLoading}
          isEmpty={!listLoading && !listError && (!list || list.length === 0)}
          error={!listLoading && listError}
          onStartTrain={refetchJob}
        />
        <Box p={1} />
        <ModelList />
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <TrainingJobDetail
          data={job}
          loading={jobLoading}
          error={jobError}
          onStartTrain={refetchJob}
        />
      </Grid>
    </Grid>
  );
}

export default ControlPanel;
