import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function ProcessLoading() {
  const { t } = useTranslation();

  return (
    <Box height="100%" width="100%" textAlign="center">
      <CircularProgress />
      <Typography color="textSecondary">{t('Loading...')}</Typography>
    </Box>
  );
}

export default ProcessLoading;
