import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "../../constants";

function ResponseTimeChart({ data }) {
  const { t } = useTranslation();
  const labels = [
    t("Under 1 second"),
    t("1 to 2 seconds"),
    t("2 to 5 seconds"),
    t("Over 5 seconds")
  ];
  const datasets = [
    {
      label: "Dataset 1",
      data,
      backgroundColor: [
        CHART_COLORS.green,
        CHART_COLORS.blue,
        CHART_COLORS.orange,
        CHART_COLORS.red
      ]
    }
  ];
  return (
    <Doughnut
      data={{ labels, datasets }}
      height={"100%"}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }}
    />
  );
}

export default ResponseTimeChart;
