import { useState } from "react";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useParams } from "react-router";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AugmentStatus from "./AugmentStatus";
import TrainingJob from "./TrainingJob";
import TrainingStatus from "./TrainingStatus";
import { useTranslation } from "react-i18next";

function TrainingJobResult() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [job, setJob] = useState();
  const [{ data: jobData, loading, error }, refetchJob] = useAxios({
    url: `/datasets/${id}/jobs`,
    params: {
      skip: 0,
      limit: 1
    }
  });

  useEffect(() => {
    if (jobData && jobData.length > 0) setJob(jobData[0]);
  }, [jobData]);

  if (loading)
    return (
      <Box textAlign="center" p={2}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Paper component={Box} textAlign="center" p={2}>
        <ErrorOutlineIcon fontSize="large" color="error" />
        <Typography color="error">
          {t("Error:")} {error.message}
        </Typography>
      </Paper>
    );

  if (!job)
    return (
      <Paper component={Box} textAlign="center" p={2}>
        <InfoOutlinedIcon fontSize="large" color="disabled" />
        <Typography color="textSecondary">{t("No training found")}</Typography>
      </Paper>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={5}>
        <TrainingJob data={job} onStartTrain={refetchJob} />
        {!job.skipAugment && (
          <Box pt={2}>
            <AugmentStatus trainingJob={job} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} lg={7}>
        <TrainingStatus trainingJob={job} />
      </Grid>
    </Grid>
  );
}

export default TrainingJobResult;
