import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "../../constants";
import ResponseTimeChart from "./ResponseTimeChart";
import useAxios from "axios-hooks";

function ResponseTimeStats() {
  const { t } = useTranslation();
  const [{ data }] = useAxios("/detect-results/duration-stats");

  return (
    <Paper component={Box} p={2} height="100%">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Box mb={1}>
            <Typography
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {t("Response Time Stats")}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">
                {t("Under 1 second")}
              </Typography>
              <Typography
                style={{ color: CHART_COLORS.green, fontWeight: "bold" }}
              >
                {(data && data.under1s) || 0}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">
                {t("1 to 2 seconds")}
              </Typography>
              <Typography
                style={{ color: CHART_COLORS.blue, fontWeight: "bold" }}
              >
                {(data && data.over1s) || 0}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">
                {t("2 to 5 seconds")}
              </Typography>
              <Typography
                style={{ color: CHART_COLORS.orange, fontWeight: "bold" }}
              >
                {(data && data.over2s) || 0}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">
                {t("Over 5 seconds")}
              </Typography>
              <Typography
                style={{ color: CHART_COLORS.red, fontWeight: "bold" }}
              >
                {(data && data.over5s) || 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ResponseTimeChart
            data={data && [data.under1s, data.over1s, data.over2s, data.over5s]}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ResponseTimeStats;
