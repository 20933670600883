import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CHART_COLORS } from "../../constants";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider } from "@material-ui/pickers/LocalizationProvider";
import { DatePicker } from "@material-ui/pickers";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";

function RecentAPICalls() {
  const limit = 10;
  const [sort, setSort] = useState("new");
  const [search, setSearch] = useState("");
  const [date, setDate] = useState();
  const [filename, setFilename] = useState("");
  const [logs, setLogs] = useState([]);
  const [skip, setSkip] = useState(0);
  const [{ data, loading }] = useAxios({
    url: "detect-results",
    params: { sort, date, filename, limit, skip }
  });
  const { t } = useTranslation();

  useEffect(
    () => {
      if (!data || data.length === 0) return;
      if (skip === 0) {
        setLogs(data);
      } else {
        setLogs(prev => [...prev, ...data]);
      }
    },
    // eslint-disable-next-line
    [data]
  );

  useEffect(() => {
    if (!search && filename) {
      setFilename("");
      setSkip(0);
      setLogs([]);
      return;
    }
    if (search.length < 3) return;
    const timer = setTimeout(() => {
      setFilename(search);
      setSkip(0);
      setLogs([]);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [search, filename]);

  useEffect(() => {
    setSkip(0);
    setLogs([]);
    if (sort !== "date") {
      setDate(null);
    }
  }, [sort]);

  const handleLoadmore = () => {
    setSkip(skip + limit);
  };

  const getColorByProb = prob => {
    if (prob > 0.9) {
      return CHART_COLORS.green;
    }
    if (prob > 0.8) {
      return CHART_COLORS.blue;
    }
    if (prob > 0.6) {
      return CHART_COLORS.orange;
    }
    return CHART_COLORS.red;
  };

  return (
    <Paper component={Box} p={2} pr={0} pb={0} height={"100%"}>
      <Box mb={1}>
        <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          {t("API Logs")}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <TextField
          placeholder={t("file name...")}
          value={search}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{t("Search")}</InputAdornment>
            )
          }}
          style={{ flexGrow: 1, marginRight: 6 }}
        />
        <Select value={sort} onChange={e => setSort(e.target.value)}>
          <MenuItem value={"new"}>{t("Newest")}</MenuItem>
          <MenuItem value={"old"}>{t("Oldest")}</MenuItem>
          <MenuItem value={"highProb"}>{t("High Probability")}</MenuItem>
          <MenuItem value={"lowProb"}>{t("Low Probability")}</MenuItem>
          <MenuItem value={"fast"}>{t("Fast Resonpse")}</MenuItem>
          <MenuItem value={"slow"}>{t("Slow Response")}</MenuItem>
          <MenuItem value={"date"}>{t("Specific Date")}</MenuItem>
        </Select>
        {sort === "date" && (
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <DatePicker
              variant="inline"
              format="MMM dd, yyyy"
              placeholder={t("Choose a date")}
              value={date}
              onChange={setDate}
              disableFuture
              renderInput={props => (
                <TextField
                  {...props}
                  helperText=""
                  style={{ maxWidth: 140, marginRight: 18 }}
                />
              )}
            />
          </LocalizationProvider>
        )}
      </Box>
      <Box component={List} maxHeight={500} overflow="auto">
        {!logs ||
          (logs.length === 0 && (
            <Box p={2}>
              <Typography align="center" color="textSecondary">
                {t("No logs found")}
              </Typography>
            </Box>
          ))}
        {logs &&
          logs.length > 0 &&
          logs.map(({ _id, timestamp, filename, duration, prob }) => (
            <ListItem key={_id} disableGutters>
              <ListItemAvatar>
                <Avatar
                  title={t("Probability")}
                  style={{ backgroundColor: getColorByProb(prob) }}
                >
                  <small>{(prob * 100).toFixed(0)}%</small>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={filename}
                secondary={`${duration}ms, ${new Date(
                  timestamp
                ).toLocaleString()}`}
              />
            </ListItem>
          ))}
        {loading && (
          <Box p={2} textAlign="center">
            <CircularProgress />
          </Box>
        )}
        {data && data.length > 0 && (
          <Button onClick={handleLoadmore}>{t("Load more")}</Button>
        )}
      </Box>
    </Paper>
  );
}

export default RecentAPICalls;
