import { Button, Grid, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import DatasetItem from "./DatasetItem";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import LoadIcon from "@material-ui/icons/Refresh";
import { useTranslation } from "react-i18next";

function DatasetList({ params, onClickSeeAll, showAll }) {
  const [datasets, setDatasets] = useState([]);
  const [currParams, setCurrParams] = useState({
    skip: 0,
    limit: 6,
    ...params
  });
  const [{ data, loading, error }, refetch] = useAxios({
    url: "/datasets",
    params
  });
  const { t } = useTranslation();
  const [{ data: currentUser }] = useAxios("/profile");

  useEffect(() => {
    if (!data) return;
    setDatasets(prev => [...prev, ...data]);
  }, [data]);

  const handleLoadMore = () => {
    const newParams = {
      ...currParams,
      skip: currParams.skip + currParams.limit,
      limit: 10
    };
    refetch({
      params: newParams
    });
    setCurrParams(newParams);
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      {datasets &&
        datasets.map(item => (
          <Grid item key={item._id}>
            <DatasetItem dataset={item} currentUser={currentUser} />
          </Grid>
        ))}
      {datasets && datasets.length > 0 && !showAll && (
        <Grid item>
          <Button onClick={onClickSeeAll} endIcon={<ArrowIcon />}>
            {t("See all")}
          </Button>
        </Grid>
      )}
      {data && data.length > 0 && showAll && (
        <Grid item>
          <Button onClick={handleLoadMore} startIcon={<LoadIcon />}>
            {t("Load more")}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        {loading && (
          <Typography color="textSecondary">{t("Loading..")}.</Typography>
        )}
        {error && (
          <Typography color="error">
            {t("Error:")} {error.message}
          </Typography>
        )}
        {(!datasets || datasets.length === 0) && (
          <Typography color="textSecondary">{t("No data")}</Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default DatasetList;
