import { Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../AppContext";

function TrainingHourLeft({ trainingJobId, initValue, isRunning }) {
  const { t } = useTranslation();
  const [hoursLeft, setHoursLeft] = useState();
  const { eventSource } = useContext(AppContext);

  useEffect(() => {
    if (initValue) {
      setHoursLeft(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    const listener = ({ data }) => {
      const json = JSON.parse(data);
      setHoursLeft(json && json.hoursLeft);
    };
    eventSource.addEventListener(
      `training_job.train_output.${trainingJobId}`,
      listener
    );
    return () => {
      eventSource.removeEventListener(
        `training_job.train_output.${trainingJobId}`,
        listener
      );
    };
  }, [trainingJobId, eventSource]);

  if (!isRunning) {
    return "";
  }
  if (isRunning) {
    if (!hoursLeft) {
      return (
        <Typography color="textSecondary">
          {t("Pre-processing data..")}.
        </Typography>
      );
    }
    if (hoursLeft < 0) {
      return (
        <Typography color="textSecondary">
          {t("Estimating remaining time...")}
        </Typography>
      );
    }
    return (
      <Typography color="textSecondary">
        {(hoursLeft && hoursLeft.toFixed(2)) || "(?)"} {t("hours left")}
      </Typography>
    );
  }
}

export default TrainingHourLeft;
