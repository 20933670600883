import React from "react";
import { Box, TextField, IconButton, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { green, red } from "@material-ui/core/colors";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    alignItems: "center"
  },
  check: {
    color: green[500]
  },
  close: {
    color: red[500]
  },
  boxButton: {
    display: "flex",
    marginLeft: 10
  },
  closeIconButton: {
    marginLeft: 5
  }
}));

const test_dataset = "from_test";
function SaveNewDataset({
  handleOpenSaveNewDataset,
  resultData,
  refetchDataset
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [, createDataset] = useAxios(
    {
      url: "/datasets",
      method: "POST"
    },
    { manual: true }
  );

  const [, AddImageToDataset] = useAxios(
    {
      method: "POST"
    },
    { manual: true }
  );
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const formDataImgAdd = new FormData();
    formDataImgAdd.append("file", resultData.value);

    createDataset({ data: formData }).then(res => {
      if (res.status === 201) {
        const { data } = res;

        form.reset();
        if (!data._id) {
          enqueueSnackbar(t("Cannot get id of dataset. Contact admin!"), {
            variant: "error"
          });
          return;
        }

        AddImageToDataset({
          url: `/datasets/${data._id}/images`,
          data: formDataImgAdd
        }).then(res => {
          if (res.status === 201) {
            enqueueSnackbar(`${t('Image is added to dataset')} ${data.name}`, {
              variant: "success"
            });
            handleOpenSaveNewDataset();
            refetchDataset();
          } else {
            enqueueSnackbar(t("Cannot save image to new dataset!"), {
              variant: "error"
            });
            return;
          }
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={styles.box}>
        <TextField
          variant="outlined"
          size="small"
          name="name"
          fullWidth
          required
          placeholder={t("Enter dataset name")}
        />
        <input type="hidden" name="type" value={test_dataset} />
        <input type="hidden" name="mode" value="contained" />
        <Box className={styles.boxButton}>
          <IconButton aria-label="Save" type="submit">
            <CheckIcon className={styles.check} />
          </IconButton>
          <IconButton
            aria-label="Close"
            className={styles.closeIconButton}
            onClick={handleOpenSaveNewDataset}
          >
            <CloseIcon className={styles.close} />
          </IconButton>
        </Box>
      </Box>
    </form>
  );
}

export default SaveNewDataset;
