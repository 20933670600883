import React from "react";
import { CHART_COLORS } from "../../constants";
import NumberBox from "../NumberBox";
import StarIcon from "@material-ui/icons/Star";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import TextureIcon from "@material-ui/icons/Texture";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";

function ApiStats() {
  const { t } = useTranslation();
  const [{ data }] = useAxios("/detect-results/req-stats");

  return (
    <NumberBox
      title={t("Detection API Stats")}
      items={[
        {
          label: t("Total # Requests"),
          value: data && data.totalReq,
          color: CHART_COLORS.purple,
          icon: <ArrowUpwardIcon />
        },
        {
          label: t("Today API Requests"),
          value: data && data.todayReq,
          color: CHART_COLORS.blue,
          icon: <StarIcon />
        },
        {
          label: t("Last Request"),
          value:
            data && data.lastReq && new Date(data.lastReq).toLocaleString(),
          color: CHART_COLORS.orange,
          icon: <TextureIcon />
        },
        {
          label: t("Avg Processing Time"),
          value: data && data.avgDuration && `${data.avgDuration.toFixed(0)}ms`,
          color: CHART_COLORS.orange,
          icon: <ScheduleIcon />
        }
      ]}
    />
  );
}

export default ApiStats;
