import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AugmentStatus from "./AugmentStatus";
import TrainingJob from "./TrainingJob";
import TrainingStatus from "./TrainingStatus";
import { useTranslation } from "react-i18next";

function TrainingJobDetail({ data, loading, error, onStartTrain }) {
  const { t } = useTranslation();

  if (loading)
    return (
      <Box textAlign="center" p={2}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Paper component={Box} textAlign="center" p={2}>
        <ErrorOutlineIcon fontSize="large" color="error" />
        <Typography color="error">
          {t("Error:")} {error.message}
        </Typography>
      </Paper>
    );

  if (!data)
    return (
      <Paper component={Box} textAlign="center" p={2}>
        <InfoOutlinedIcon fontSize="large" color="disabled" />
        <Typography color="textSecondary">{t("No training found")}</Typography>
      </Paper>
    );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TrainingJob data={data} onStartTrain={onStartTrain} />
        </Grid>
        {!data.skipAugment && (
          <Grid item xs={12}>
            <AugmentStatus trainingJob={data} />
          </Grid>
        )}
        <Grid item xs={12}>
          <TrainingStatus trainingJob={data} />
        </Grid>
        {/* <Grid item xs={12}>
          <TestingStatus trainingJob={data} />
        </Grid> */}
      </Grid>
    </>
  );
}

export default TrainingJobDetail;
