import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    minWidth: 320
  },
  media: {
    height: 140
  }
});

export default function AccountItem({ data }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayName, role, username } = data;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {displayName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t("Role:")} {role}
            <br />
            {t("Username:")} {username}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
