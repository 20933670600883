import { Box, List, Paper, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import ProcessError from "./ProcessError";
import ProcessLoading from "./ProcessLoading";
import ModelItem from "./ModelItem";
import ImportModelButton from "./ImportModelButton";

function ModelList() {
  const { t } = useTranslation();
  const [{ data, loading, error }, refetch] = useAxios("/models");

  return (
    <Paper elevation={1}>
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          {t("Available models")}
        </Typography>
        <ImportModelButton onCreated={refetch} />
      </Box>
      {loading && <ProcessLoading />}
      {error && <ProcessError error={error} />}
      <List disablePadding>
        {data &&
          data.map(item => (
            <ModelItem key={item._id} data={item} onChanges={refetch} />
          ))}
      </List>
    </Paper>
  );
}

export default ModelList;
