import { Box, CircularProgress, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";

export function CircularProgressWithLabel(props) {
  return (
    <Box
      position="relative"
      display="inline-flex"
      bgcolor="#f2f2f2"
      borderRadius="50%"
    >
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {Math.round(props.value) === 100 ? (
          <CheckIcon color="primary" />
        ) : (
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}
