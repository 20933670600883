const API_HOST_DEV = "http://localhost:8000";
const API_HOST_PRO = process.env.REACT_APP_API || "https://cad-api.besoft.vn";
export const API_BASE = process.env.NODE_ENV === "production" ? `${API_HOST_PRO}/api` : `${API_HOST_DEV}/api`;
export const API_HOST = process.env.NODE_ENV === "production" ? API_HOST_PRO : API_HOST_DEV;

export const APP_VERSION = "2.46"

export const DATASET_STATES = {
  pending: 'Wait for processing',
  extracting: 'Extracting',
  extracted: 'Ready to annotate',
  annotating: 'Annotating',
  annotated: 'Ready to train',
  training: 'Training',
  finish: 'Trained',
};

export const DATASET_MODES = {
  contained: "contained",
  fully: 'fully',
  none: 'none'
}

export const TRAIN_STATES = {
  pending: 'Pending',
  compiling: 'Compiling',
  augmenting: 'Augmenting data',
  training: 'Training',
  testing: 'Testing',
  finish: 'Finished',
};

export const CHART_COLORS = {
  red: '#FF6384',
  orange: '#FF9F40',
  yellow: '#FFCD56',
  green: '#4BC0C0',
  blue: '#36A2EB',
  purple: '#9966FF',
  grey: '#C9CBCF'
};
