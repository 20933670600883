import React, { useEffect, useState } from "react";
import { Box, List, Button, Paper, Typography } from "@material-ui/core";
import ImageItem from "./ImageItem";
import { useTranslation } from "react-i18next";

function ImageTestList({ images, onChangeCurrentResult, onStatsChange }) {
  const [startDetect, setStartDetect] = useState(false);
  const [currentSelected, setCurrentSelected] = useState("");
  const [stats, setStats] = useState({});
  const { t } = useTranslation();

  const handleChangeResultView = (index, val) => {
    onChangeCurrentResult(val);
    setCurrentSelected(index);
  };

  const handleData = ({ duration, results, imagePath }) => {
    setStats(prev => {
      const { avgProcessTime, allDetected, avgProb, images } = prev;
      if (images && images.includes(imagePath)) return prev;
      const prob =
        results.reduce((prevProb, { prob }) => ((prevProb ?? prob) + prob) / 2, null) ??
        1;
      return {
        avgProcessTime: ((avgProcessTime ?? +duration) + duration) / 2,
        allDetected: (allDetected ?? 0) + results.length,
        avgProb: ((avgProb ?? prob) + prob) / 2,
        images: [...(images || []), imagePath]
      };
    });
  };

  const handleStartDetect = () => {
    setStartDetect(true);
    setStats({});
    setTimeout(() => {
      setStartDetect(false);
    }, 10);
  };

  useEffect(() => {
    onStatsChange(stats);
  }, [stats, onStatsChange]);

  useEffect(() => {
    setStartDetect(false);
    setCurrentSelected();
    setStats({});
  }, [images]);

  return (
    <Paper component={Box} p={2}>
      <Typography
        style={{
          textTransform: "uppercase",
          fontWeight: "bold",
          marginBottom: 6,
          textAlign: "center"
        }}
      >
        {t("Images List")}
      </Typography>
      <hr />
      <Box py={2} style={{ maxHeight: "47.5vh", overflow: "auto" }}>
        {images.length === 0 && (
          <Typography color="textSecondary">
            {t("Please upload image")}
          </Typography>
        )}
        {images.length > 0 && (
          <List component="div">
            {images.map((img, i) => (
              <ImageItem
                key={img.name}
                img={img}
                selected={currentSelected === i}
                onSelected={data => handleChangeResultView(i, data)}
                startDetect={startDetect}
                onData={handleData}
              />
            ))}
          </List>
        )}
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleStartDetect}
        disabled={images.length === 0}
        style={{ marginTop: 10 }}
      >
        {t("Detect all")}
      </Button>
    </Paper>
  );
}

export default ImageTestList;
