import React, { memo, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

function TrainingStatisticChart({ data }) {
  const ref = useRef();
  const { t } = useTranslation();
  const datasets = [
    {
      label: t("Accuracy"),
      data: [],
      fill: false,
      backgroundColor: "rgb(55, 200, 32)",
      borderColor: "rgb(55, 200, 32)",
      borderWidth: 3,
      type: "line",
      yAxisID: "y0",
      order: 0
    },
    {
      label: t("Training Time (hrs)"),
      data: [],
      fill: false,
      backgroundColor: "rgb(255 165 0)",
      borderColor: "rgb(255 165 0)",
      maxBarThickness: 24,
      borderWidth: 3,
      yAxisID: "y1",
      order: 1
    }
  ];

  useEffect(() => {
    if (!data) return;
    for (let index = data.length - 1; index >= 0; index--) {
      const { trainingTime, avgAccuracy, createdAt } = data[index];
      if (avgAccuracy > 0) {
        ref.current.data.datasets[0].data.push({
          x: new Date(createdAt),
          y: Math.floor(avgAccuracy * 100) / 100
        });
        ref.current.data.datasets[1].data.push({
          x: new Date(createdAt),
          y: Math.floor(trainingTime / 1000 / 60 / 60)
        });
      }
    }
    ref.current.update();
  }, [data]);

  return (
    <Bar
      ref={ref}
      data={{ datasets }}
      options={{
        plugins: {
          legend: {
            position: "bottom"
          },
        },
        scales: {
          xAxis: {
            type: "time",
            time: {
              unit: "day"
            }
          },
          y0: {
            position: "left",
            suggestedMax: 100,
            suggestedMin: 0,
            type: "linear"
          },
          y1: {
            position: "right",
            suggestedMax: 50,
            suggestedMin: 0,
            type: "linear",
            display: false,
            grid: {
              display: false
            }
          }
        }
      }}
    />
  );
}

export default memo(TrainingStatisticChart);
