import React from "react";
import { Box, Paper, Typography, CircularProgress } from "@material-ui/core";
import ListDataset from "./ListDataset";
import { useTranslation } from "react-i18next";

function Improvement({
  currentResult,
  handleChangeDataset,
  selectedDataset,
  listData
}) {
  const { t } = useTranslation();

  return (
    <Paper component={Box} p={2} height="100%">
      <Typography
        style={{
          textTransform: "uppercase",
          fontWeight: "bold",
          marginBottom: 6
        }}
      >
        {t("Test Dataset")}
      </Typography>
      {!listData && <CircularProgress />}
      {listData && listData.length === 0 && (
        <Typography color="textSecondary">
          {t("No test dataset found")}
        </Typography>
      )}
      {listData && listData.length > 0 && (
        <ListDataset
          listData={listData}
          currentResult={currentResult}
          selectedDataset={selectedDataset}
          handleChangeDataset={handleChangeDataset}
        />
      )}
    </Paper>
  );
}

export default Improvement;
