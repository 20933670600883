import { Box, Collapse, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import DatasetList from "./DatasetList";
import { DATASET_STATES } from "../../constants";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

function DatasetPage() {
  const [display, setDisplay] = useState();
  const { t } = useTranslation();

  return (
    <>
      <Collapse in={!display || display === "from_test"}>
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            mt={4}
          >
            <Typography variant="h5">{t("From Testing")}</Typography>
            {display && (
              <IconButton onClick={() => setDisplay()}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <DatasetList
            params={{ limit: 3, type: "from_test" }}
            onClickSeeAll={() => setDisplay("from_test")}
            showAll={display === "from_test"}
          />
        </div>
      </Collapse>
      <Collapse in={!display || display === "annotating"}>
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            mt={4}
          >
            <Typography variant="h5">{t("Annotating")}</Typography>
            {display && (
              <IconButton onClick={() => setDisplay()}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <DatasetList
            params={{ limit: 3, states: [DATASET_STATES.annotating] }}
            onClickSeeAll={() => setDisplay("annotating")}
            showAll={display === "annotating"}
          />
        </div>
      </Collapse>
      <Collapse in={!display || display === "annotated"}>
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            mt={4}
          >
            <Typography variant="h5">{t("Ready To Train")}</Typography>
            {display && (
              <IconButton onClick={() => setDisplay()}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <DatasetList
            params={{ limit: 3, states: [DATASET_STATES.annotated] }}
            onClickSeeAll={() => setDisplay("annotated")}
            showAll={display === "annotated"}
          />
        </div>
      </Collapse>
      <Collapse in={!display || display === "finish"}>
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            mt={4}
          >
            <Typography variant="h5">{t("Finished Training")}</Typography>
            {display && (
              <IconButton onClick={() => setDisplay()}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <DatasetList
            params={{ limit: 3, states: [DATASET_STATES.finish] }}
            onClickSeeAll={() => setDisplay("finish")}
            showAll={display === "finish"}
          />
        </div>
      </Collapse>
    </>
  );
}

export default DatasetPage;
