import { Box, IconButton, Paper, Typography } from "@material-ui/core";
import React from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/Close";

function ChartWrapper({ title, actions, children, ...props }) {
  const handle = useFullScreenHandle();

  return (
    <Paper component={Box} height="100%" {...props}>
      <Box
        component={FullScreen}
        p={2}
        pt={1}
        height="100%"
        handle={handle}
        bgcolor="white"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mr={-1}
        >
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <Box display="flex" alignItems="center">
            {actions}
            <IconButton
              title={handle.active ? "Exit fullscreen" : "Fullscreen"}
              onClick={handle.active ? handle.exit : handle.enter}
            >
              {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
        </Box>
        {children}
      </Box>
    </Paper>
  );
}

export default ChartWrapper;
