import useAxios from "axios-hooks";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TRAIN_STATES } from "../../constants";
import useServerEvent from "../../helpers/useEvent";
import ChartWrapper from "../ChartWrapper";
import TrainingHourLeft from "./TrainingHourLeft";
import TrainingOutputChart from "./TrainingOutputChart";

function TrainingStatus({ trainingJob }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [{ data: outputs }] = useAxios(
    `training-jobs/${trainingJob._id}/train-outputs`,
    {
      manual: !trainingJob._id
    }
  );
  const [{ state }, setState] = useServerEvent(
    `training_job.update.${trainingJob._id}`,
    {
      state: trainingJob.state
    }
  );

  useEffect(() => {
    setState({ state: trainingJob.state });
  }, [trainingJob.state, setState]);

  useEffect(() => {
    if (outputs) {
      setData(outputs);
    }
  }, [outputs]);

  return (
    <ChartWrapper
      title={t("Training results")}
      actions={
        <TrainingHourLeft
          trainingJobId={trainingJob._id}
          initValue={data.length && data[0].hoursLeft}
          isRunning={state === TRAIN_STATES.training}
        />
      }
      elevation={state === TRAIN_STATES.training ? 6 : 1}
    >
      <TrainingOutputChart trainingJobId={trainingJob._id} data={data} />
    </ChartWrapper>
  );
}

export default TrainingStatus;
