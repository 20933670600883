import {
  Box,
  Button,
  Chip,
  Collapse,
  Paper,
  Typography
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { API_BASE, DATASET_MODES, DATASET_STATES } from "../../constants";
import TrainingConfirmDialog from "../training/TrainingConfirmDialog";
import DeleteConfirmDialog from "./DeleteConfirmDialog";

function DatasetItem({ dataset, currentUser }) {
  const history = useHistory("datasets");
  const [openDelete, setOpenDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const { _id, createdAt, createdBy, imageCount, name, state, mode } = dataset;
  const { t } = useTranslation();

  return (
    <Collapse in={!deleted}>
      <Paper component={Box} overflow="hidden" width={300}>
        <Box
          width="100%"
          height="200px"
          style={{
            backgroundImage: `url('${API_BASE}/datasets/${_id}/thumbnail')`,
            backgroundSize: "cover",
            backgroundColor: "lightgrey"
          }}
        />
        <Box p={2}>
          <Chip
            size="small"
            component={Box}
            label={t(state)}
            color="primary"
            mb={1}
          />
          <Chip
            size="small"
            component={Box}
            label={`${imageCount || "0"} ${t("images")}`}
            mb={1}
            ml={1}
          />
          <Typography>{name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {new Date(createdAt).toLocaleString()}
          </Typography>
          {createdBy && (
            <Typography variant="body2" color="textSecondary">
              {t("Created by")} {createdBy}
            </Typography>
          )}
          <Box mt={2} mx={-1}>
            {(state === DATASET_STATES.finish ||
              state === DATASET_STATES.annotated) && (
              <Button color="primary" onClick={() => setOpenDialog(true)}>
                {t("Train")}
              </Button>
            )}
            {state === DATASET_STATES.finish && (
              <Button onClick={() => history.push(`/training/${_id}`)}>
                {t("View Result")}
              </Button>
            )}
            {mode === DATASET_MODES.contained && (
              <Button onClick={() => history.push(`/annotate/${_id}`)}>
                {t("Annotate")}
              </Button>
            )}
            {currentUser && currentUser.username === createdBy && (
              <Button color="secondary" onClick={() => setOpenDelete(true)}>
                {t("Delete")}
              </Button>
            )}
          </Box>
        </Box>
        {(state === DATASET_STATES.finish ||
          state === DATASET_STATES.annotated) && (
          <TrainingConfirmDialog
            datasetId={_id}
            open={openDialog}
            onClose={() => setOpenDialog(false)}
          />
        )}
        <DeleteConfirmDialog
          datasetId={_id}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          onSuccess={() => setDeleted(true)}
        />
      </Paper>
    </Collapse>
  );
}

export default DatasetItem;
