import { useContext, useEffect, useState } from "react";
import { AppContext } from "../components/AppContext";

/**
 * 
 * @param {string} event 
 * @param {any} initialValue 
 * @returns {[data: any, setData: void ]}
 */
function useServerEvent(event, initialValue) {
  const { eventSource } = useContext(AppContext);
  const [data, setData] = useState(initialValue);

  useEffect(() => {
    const listener = ({ data }) => {
      const json = JSON.parse(data);
      setData(prevData => ({ ...prevData, ...json }));
    };
    eventSource.addEventListener(event, listener);
    return () => {
      eventSource.removeEventListener(event, listener);
    };
  }, [event, eventSource]);

  return [data, setData];
}

export default useServerEvent;
