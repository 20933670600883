import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControlLabel,
  Input,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import useAxios from "axios-hooks";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 24
  }
}));

function DatasetCreate({ onNewData }) {
  const styles = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [{ loading, error }, createDataset] = useAxios(
    {
      url: "/datasets",
      method: "POST"
    },
    { manual: true }
  );
  const [progress, setProgress] = useState();
  const [datasetMode, setDatasetMode] = useState("contained");
  const [clsname, setClsname] = useState("blueprint");
  const [{ data: classifications }] = useAxios("/classifications", {
    manual: datasetMode !== "fully"
  });

  const handleUploadProgress = ({ lengthComputable, loaded, total }) => {
    if (lengthComputable) {
      setProgress((loaded / total) * 100);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    createDataset({
      data: formData,
      onUploadProgress: handleUploadProgress
    }).then(res => {
      if (res.status === 201) {
        form.reset();
        onNewData(res.data);
        enqueueSnackbar(t("Dataset is created successfully"), {
          variant: "success"
        });
      }
    });
  };

  useEffect(
    () => {
      if (error) {
        enqueueSnackbar(t("Failed to create dataset"), {
          variant: "error"
        });
      }
    },
    // eslint-disable-next-line
    [error, enqueueSnackbar]
  );

  return (
    <Paper component={Box} p={2}>
      <form onSubmit={handleSubmit}>
        <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          {t("New dataset")}
        </Typography>
        <TextField
          className={styles.input}
          variant="outlined"
          margin="dense"
          name="name"
          fullWidth
          required
          placeholder={t("Enter dataset name")}
        />
        <Input
          margin="none"
          type="file"
          label={t("File (.zip)")}
          name="file"
          fullWidth
          required
          disableUnderline
          inputProps={{
            accept: ".zip"
          }}
        />
        <Typography variant="caption" color="textSecondary">
          {t("Only support .zip file")}
        </Typography>
        <Collapse in={loading}>
          <Box pt={1} display="flex" alignItems="center">
            <Box flexGrow={1} mr={1}>
              <LinearProgress variant={"determinate"} value={progress} />
            </Box>
            {progress && (
              <Typography variant="caption" color="textSecondary">
                {progress.toFixed(2)}%
              </Typography>
            )}
          </Box>
        </Collapse>
        <Box className={styles.input}>
          <RadioGroup
            name="mode"
            style={{ marginTop: 18 }}
            value={datasetMode}
            onChange={e => setDatasetMode(e.target.value)}
          >
            <Tooltip title="Each image contains one or more objects">
              <FormControlLabel
                value="contained"
                control={<Radio />}
                label={t("Annotation Required")}
              />
            </Tooltip>
            <Tooltip title="Do not have any object in the images so do not need to annotate">
              <FormControlLabel
                value="none"
                control={<Radio />}
                label={t("All No Blueprints")}
              />
            </Tooltip>
            <Tooltip title="Entire image is an object itself">
              <FormControlLabel
                value="fully"
                control={<Radio />}
                label={t("All Blueprints")}
              />
            </Tooltip>
          </RadioGroup>
          <Collapse in={datasetMode === "fully"}>
            <TextField
              variant="outlined"
              margin="dense"
              name="cls[]"
              fullWidth
              required
              value={clsname}
              onChange={e => setClsname(e.target.value)}
              placeholder="Enter a name for all images"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Classname:</InputAdornment>
                )
              }}
            />
            {classifications?.map(({ _id, name }) => (
              <Chip
                key={_id}
                label={name}
                clickable
                onClick={() => setClsname(name)}
                size="small"
              />
            ))}
          </Collapse>
        </Box>
        <Button
          type="submit"
          disabled={loading}
          variant="contained"
          color="primary"
        >
          {loading ? t("Submitting...") : t("Submit")}
        </Button>
        <Input type="hidden" name="type" value="user_upload" />
      </form>
    </Paper>
  );
}

export default DatasetCreate;
