import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Checkbox
} from "@material-ui/core";
import StorageIcon from "@material-ui/icons/Storage";

function ListDataset({
  listData,
  currentResult,
  selectedDataset,
  handleChangeDataset
}) {
  return (
    <List
      component="div"
      py={2}
      style={{ maxHeight: "20vh", overflow: "auto" }}
    >
      {listData.map((d, i) => (
        <ListItem
          key={`${i}-count`}
          style={{
            backgroundColor:
              selectedDataset && selectedDataset.id === d._id
                ? "#f0f2fc"
                : "transparent"
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <StorageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
            primary={d.name}
            secondary={`${d.imageCount || 0} images`}
          />
          {currentResult && (
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={() =>
                  handleChangeDataset({ name: d.name, id: d._id })
                }
                checked={selectedDataset && d._id === selectedDataset.id}
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
}

export default ListDataset;
