import { Box, Grid, Paper, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "../../constants";
import ProbabilityDistributionChart from "./ProbabilityDistributionChart";

function ProbabilityDistributionStats() {
  const { t } = useTranslation();
  const [{ data }] = useAxios("/detect-results/prob-stats");

  return (
    <Paper component={Box} p={2} height="100%">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Box mb={1}>
            <Typography
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {t("Probability Distribution")}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">
                {t("Over 90% probability")}
              </Typography>
              <Typography
                style={{ color: CHART_COLORS.green, fontWeight: "bold" }}
              >
                {data && data.over90}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">
                {t("80% to 90% probability")}
              </Typography>
              <Typography
                style={{ color: CHART_COLORS.blue, fontWeight: "bold" }}
              >
                {data && data.over80}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">
                {t("60% to 80% probability")}
              </Typography>
              <Typography
                style={{ color: CHART_COLORS.orange, fontWeight: "bold" }}
              >
                {data && data.over60}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">
                {t("Under 60% probability")}
              </Typography>
              <Typography
                style={{ color: CHART_COLORS.red, fontWeight: "bold" }}
              >
                {data && data.under60}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ProbabilityDistributionChart
            data={data && [data.over90, data.over80, data.over60, data.under60]}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ProbabilityDistributionStats;
