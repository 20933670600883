import useAxios from "axios-hooks";
import React from "react";
import { CHART_COLORS } from "../../constants";
import { convert2HHMMSS } from "../../helpers/converter";
import NumberBox from "../NumberBox";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import TimerIcon from "@material-ui/icons/Timer";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import { useTranslation } from "react-i18next";

function ModelStats() {
  const { t } = useTranslation();
  const [{ data }] = useAxios("/model-stats");

  return (
    <NumberBox
      title={t("Detection Model Stats")}
      items={[
        {
          label: t("Current Accuracy"),
          value: data && data.accuracy && `${(data.accuracy * 1).toFixed(2)}%`,
          color: CHART_COLORS.green,
          icon: <AllInclusiveIcon />
        },
        {
          label: t("Avg Detection Time"),
          value:
            data && data.detectionTime && `${data.detectionTime.toFixed(0)}ms`,
          color: CHART_COLORS.blue,
          icon: <TimerIcon />
        },
        {
          label: t("Avg Loss"),
          value: data && data.loss,
          color: CHART_COLORS.orange,
          icon: <BlurOnIcon />
        },
        {
          label: t("Total Training Time"),
          value: data && data.trainingTime && convert2HHMMSS(data.trainingTime),
          color: CHART_COLORS.purple,
          icon: <HourglassFullIcon />
        }
      ]}
    />
  );
}

export default ModelStats;
