import React, { useEffect, useRef } from "react";
import { DateRangeDelimiter, DateRangePicker } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider } from "@material-ui/pickers/LocalizationProvider";
import { Box, TextField } from "@material-ui/core";

function DateRangeSelect({ onChange }) {
  const ref = useRef();
  const [selectedDate, handleDateChange] = React.useState([
    new Date(new Date(Date.now() - 7 * 86400000).setHours(0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59))
  ]);

  const handleChange = ([start, end]) => {
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);
    handleDateChange([start, end]);
  };

  useEffect(() => {
    if (!onChange) return;
    onChange(selectedDate);
  }, [selectedDate, onChange]);

  return (
    <Box ref={ref} mr={2}>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <DateRangePicker
          value={selectedDate}
          onChange={handleChange}
          disableFuture
          PopperProps={{
            container: () => ref.current,
            placement: "bottom-end"
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                helperText=""
                label=""
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: { color: "grey" }
                }}
                style={{ width: 90 }}
              />
              <DateRangeDelimiter style={{ color: "grey" }}>
                to
              </DateRangeDelimiter>
              <TextField
                {...endProps}
                helperText=""
                label=""
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: { color: "grey" }
                }}
                style={{ width: 90 }}
              />
            </>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default DateRangeSelect;
