import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function ProcessEmpty() {
  const { t } = useTranslation();

  return (
    <Box height="100%" width="100%" textAlign="center">
      <InfoOutlinedIcon fontSize="large" color="disabled" />
      <Typography color="textSecondary">
        {t("Nothing to do or doing")}
      </Typography>
    </Box>
  );
}

export default ProcessEmpty;
