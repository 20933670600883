import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "../../constants";
import ResponseTimeChart from "./ResponseTimeChart";
import useAxios from "axios-hooks";
import NumberBox from "../NumberBox";
import { useState } from "react";
import { useEffect } from "react";
import { AppContext } from "../AppContext";
import { useContext } from "react";

function ResponseTime() {
  const { t } = useTranslation();
  const [{ data }] = useAxios("/detect-results/duration-stats");
  const [stats, setStats] = useState({
    under1s: 0,
    over1s: 0,
    over2s: 0,
    over5s: 0
  });

  useEffect(() => {
    if (data) {
      setStats(data);
    }
  }, [data]);

  const { eventSource } = useContext(AppContext);

  useEffect(() => {
    const listener = ({ data }) => {
      const { duration } = JSON.parse(data);
      if (duration < 1000) {
        setStats(prev => ({
          ...prev,
          under1s: prev.under1s + 1
        }));
      } else if (duration < 2000) {
        setStats(prev => ({
          ...prev,
          over1s: prev.over1s + 1
        }));
      } else if (duration < 5000) {
        setStats(prev => ({
          ...prev,
          over2s: prev.over2s + 1
        }));
      } else {
        setStats(prev => ({
          ...prev,
          over5s: prev.over5s + 1
        }));
      }
    };
    eventSource.addEventListener(`detect_image`, listener);
    return () => {
      eventSource.removeEventListener(`detect_image`, listener);
    };
  }, [eventSource]);

  return (
    <NumberBox
      title={t("Response Time Stats")}
      items={[
        {
          label: "ResponseTime",
          component: (
            <Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textSecondary">
                  {t("Under 1 second")}
                </Typography>
                <Typography
                  style={{ color: CHART_COLORS.green, fontWeight: "bold" }}
                >
                  {stats.under1s || 0}
                </Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textSecondary">
                  {t("1 to 2 seconds")}
                </Typography>
                <Typography
                  style={{ color: CHART_COLORS.blue, fontWeight: "bold" }}
                >
                  {stats.over1s || 0}
                </Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textSecondary">
                  {t("2 to 5 seconds")}
                </Typography>
                <Typography
                  style={{ color: CHART_COLORS.orange, fontWeight: "bold" }}
                >
                  {stats.over2s || 0}
                </Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textSecondary">
                  {t("Over 5 seconds")}
                </Typography>
                <Typography
                  style={{ color: CHART_COLORS.red, fontWeight: "bold" }}
                >
                  {stats.over5s || 0}
                </Typography>
              </Box>
            </Box>
          )
        },
        {
          label: "ProbabilityDistributionChart",
          component: (
            <Box position="relative" width={160} margin="auto">
              <ResponseTimeChart data={data} />
            </Box>
          )
        }
      ]}
    />
  );
}

export default ResponseTime;
