import { Grid } from "@material-ui/core";
import React from "react";
import ApiRequestChart from "./ApiRequestChart";
import ApiStats from "./ApiStats";
import ProbabilityDistributionStats from "./ProbabilityDistributionStats";
import RecentAPICalls from "./RecentAPICalls";
import ResponseTimeStats from "./ResponseTimeStats";

function ApiManagerPage() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <ApiStats />
      </Grid>
      <Grid item xs={12} lg={4}>
        <ProbabilityDistributionStats />
      </Grid>
      <Grid item xs={12} lg={4}>
        <ResponseTimeStats />
      </Grid>
      <Grid item xs={12} lg={4}>
        <RecentAPICalls />
      </Grid>
      <Grid item xs={12} lg={8}>
        <ApiRequestChart />
      </Grid>
    </Grid>
  );
}

export default ApiManagerPage;
