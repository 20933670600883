import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";

function DeleteConfirmDialog({ datasetId, onSuccess, ...props }) {
  const { enqueueSnackbar } = useSnackbar();
  const [{ loading }, startDelete] = useAxios(
    {
      url: `/datasets/${datasetId}`,
      method: "DELETE"
    },
    { manual: true }
  );
  const { t } = useTranslation();

  const handleStartDelete = () => {
    startDelete().then(res => {
      if (res.status === 200) {
        if (onSuccess) {
          onSuccess();
        }
        enqueueSnackbar(t("The dataset is deleted"), { variant: "success" });
        props.onClose();
      } else {
        enqueueSnackbar(t("Failed to delete the dataset"), {
          variant: "error"
        });
      }
    });
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t("Are you sure to delete?")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("There are no way to undone")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t("Cancel")}</Button>
        <Button
          onClick={handleStartDelete}
          color="secondary"
          disabled={loading}
        >
          {t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmDialog;
