import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slider,
  Typography
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";

function TrainingConfirmDialog({ datasetId, onStartTrain, ...props }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [iteration, setIteration] = useState(6000);
  const [cpus, setCpus] = useState(6);
  const [memory, setMemory] = useState(12);
  const [learningRate, setLearningRate] = useState(-3);
  const [{ data: system, loading: checkingSystem }] = useAxios(
    {
      url: `/system`,
      params: {
        include: ["cpus", "totalmem"]
      }
    },
    { manual: !props.open }
  );
  const [{ loading, error }, startTraining] = useAxios(
    {
      url: `/train/${datasetId}`,
      method: "POST"
    },
    { manual: true }
  );

  const handleStartTrain = () => {
    startTraining({
      params: {
        maxIteration: iteration,
        cpus,
        memory,
        learning_rate: Math.pow(10, learningRate).toFixed(
          Math.abs(learningRate)
        )
      }
    }).then(res => {
      if (res.status === 201) {
        if (onStartTrain) {
          onStartTrain();
        }
        enqueueSnackbar(t("The training is started"), { variant: "success" });
        props.onClose();
      } else {
        enqueueSnackbar(t("Failed to start the training"), {
          variant: "error"
        });
      }
    });
  };

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <DialogTitle>{t("Start training?")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("Training iterations")}</DialogContentText>
        <Box p={3} pt={4}>
          <Slider
            value={iteration}
            onChange={(e, value) => setIteration(value)}
            step={2000}
            max={20000}
            min={2000}
            marks={[
              { value: 2000, label: "2000" },
              { value: 6000, label: "6000" },
              { value: 10000, label: "10000" },
              { value: 20000, label: "20000" }
            ]}
            valueLabelDisplay="on"
          />
        </Box>
        <DialogContentText component="div">
          {t("Limit number of CPU")} {!system && "(Checking...)"}
          {system && system.cpus < 6 && (
            <Typography color="error">
              {t("The server must have at least 6 CPU to start training")}
            </Typography>
          )}
        </DialogContentText>
        <Box
          p={3}
          pt={4}
          display={system && system.cpus < 6 ? "none" : "block"}
        >
          <Slider
            value={cpus}
            onChange={(e, value) =>
              system && value < system.cpus && value > 4 && setCpus(value)
            }
            step={1}
            max={system && system.cpus}
            min={1}
            disabled={!system}
            marks={[
              { value: 1, label: "1 CPU" },
              {
                value: system && system.cpus,
                label: system && `${system.cpus} CPU`
              }
            ]}
            valueLabelDisplay="on"
          />
        </Box>
        <DialogContentText component="div">
          {t("Limit amount of memory")} {!system && "(Checking...)"}
          {system && system.totalmem < 12 && (
            <Typography color="error">
              {t("The server must have at least 12G memory to start training")}
            </Typography>
          )}
        </DialogContentText>
        <Box
          p={3}
          pt={4}
          display={system && system.totalmem < 12 ? "none" : "block"}
        >
          <Slider
            value={memory}
            onChange={(e, value) =>
              system &&
              value < system.totalmem &&
              value > 10 &&
              setMemory(value)
            }
            step={1}
            max={system && system.totalmem}
            min={1}
            disabled={!system}
            marks={[
              { value: 1, label: "1G" },
              {
                value: system && system.totalmem,
                label: system && `${system.totalmem}G`
              }
            ]}
            valueLabelDisplay="on"
          />
        </Box>
        <DialogContentText>{t("Initial learning rate")}</DialogContentText>
        <Box p={3} pt={4}>
          <Slider
            value={learningRate}
            onChange={(e, value) => setLearningRate(value)}
            step={1}
            max={-1}
            min={-6}
            marks={[
              { value: -1, label: "10^(-1)" },
              { value: -2, label: "10^(-2)" },
              { value: -3, label: "10^(-3)" },
              { value: -4, label: "10^(-4)" },
              { value: -5, label: "10^(-5)" },
              { value: -6, label: "10^(-6)" }
            ]}
            valueLabelDisplay="on"
          />
        </Box>
        <DialogContentText>{t("Notice: Do not run more than one training at the same time")}</DialogContentText>
        <DialogContentText color="error">
          {error && (error.response?.data?.message ?? error.message)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t("Cancel")}</Button>
        <Button
          onClick={handleStartTrain}
          color="primary"
          disabled={
            loading ||
            checkingSystem ||
            (system && (system.totalmem < 12 || system.cpus < 6))
          }
        >
          {t("Start training")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TrainingConfirmDialog;
