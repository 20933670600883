import useAxios from "axios-hooks";
import React, { useState } from "react";
import { TRAIN_STATES } from "../../constants";
import TrainingStatisticChart from "./TrainingStatisticChart";
import ChartWrapper from "../ChartWrapper";
import DateRangeSelect from "../DateRangeSelect";
import { useTranslation } from "react-i18next";

function TrainingStatistic() {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState([]);
  const [{ data }] = useAxios(
    {
      url: "/training-jobs",
      params: {
        skip: 0,
        limit: 0,
        states: [TRAIN_STATES.finish],
        from: dateRange[0],
        to: dateRange[1]
      }
    },
    { manual: !dateRange[0] }
  );

  return (
    <ChartWrapper
      title={t("Training Statistic")}
      actions={<DateRangeSelect onChange={setDateRange} />}
    >
      <TrainingStatisticChart data={data} />
    </ChartWrapper>
  );
}

export default TrainingStatistic;
