import React from "react";
import { Box, Button, Input, Paper, Typography } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useTranslation } from "react-i18next";

function UploadImage({ onChangeImages }) {
  const { t } = useTranslation();

  return (
    <Paper component={Box} p={2} height="100%">
      <Typography
        style={{
          textTransform: "uppercase",
          fontWeight: "bold",
          marginBottom: 6
        }}
      >
        {t("Upload Images")}
      </Typography>
      <Box py={2} style={{ textAlign: "center", paddingTop: 40 }}>
        <Button
          variant="contained"
          color="primary"
          component="label"
          startIcon={<PhotoCamera />}
        >
          {t("Select File")}
          <Input
            id="input-image"
            margin="dense"
            type="file"
            name="file"
            fullWidth
            required
            disableUnderline
            onChange={onChangeImages}
            inputProps={{
              accept: "image/*",
              multiple: true
            }}
            style={{ display: "none" }}
          />
        </Button>
      </Box>
      <Typography
        color="textSecondary"
        style={{
          textTransform: "none",
          marginTop: 40
        }}
      >
        {t(
          "Note: You can select one or multiple images for detection, after that, press Detect All inside IMAGES LIST section for detecting."
        )}
      </Typography>
    </Paper>
  );
}

export default UploadImage;
