import { Box, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "../../constants";
import { AppContext } from "../AppContext";
import NumberBox from "../NumberBox";
import ProbabilityDistributionChart from "./ProbabilityDistributionChart";

function ProbabilityDistribution() {
  const { t } = useTranslation();
  const [{ data }] = useAxios("/detect-results/prob-stats");
  const { eventSource } = useContext(AppContext);
  const [stats, setStats] = useState({
    under60: 0,
    over90: 0,
    over80: 0,
    over60: 0
  });

  useEffect(() => {
    if (data) {
      setStats(data);
    }
  }, [data]);

  useEffect(() => {
    const listener = ({ data }) => {
      const { prob } = JSON.parse(data);
      if (prob >= 0.9) {
        setStats(prev => ({
          ...prev,
          over90: prev.over90 + 1
        }));
      } else if (prob >= 0.8) {
        setStats(prev => ({
          ...prev,
          over80: prev.over80 + 1
        }));
      } else if (prob >= 0.6) {
        setStats(prev => ({
          ...prev,
          over60: prev.over60 + 1
        }));
      } else {
        setStats(prev => ({
          ...prev,
          under60: prev.under60 + 1
        }));
      }
    };
    eventSource.addEventListener(`detect_image`, listener);
    return () => {
      eventSource.removeEventListener(`detect_image`, listener);
    };
  }, [eventSource]);

  return (
    <NumberBox
      title={t("Probability Distribution")}
      items={[
        {
          label: "ProbabilityDistribution",
          component: (
            <Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textSecondary">
                  {t("Over 90% probability")}
                </Typography>
                <Typography
                  style={{ color: CHART_COLORS.green, fontWeight: "bold" }}
                >
                  {stats.over90}
                </Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textSecondary">
                  {t("80% to 90% probability")}
                </Typography>
                <Typography
                  style={{ color: CHART_COLORS.blue, fontWeight: "bold" }}
                >
                  {stats.over80}
                </Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textSecondary">
                  {t("60% to 80% probability")}
                </Typography>
                <Typography
                  style={{ color: CHART_COLORS.orange, fontWeight: "bold" }}
                >
                  {stats.over60}
                </Typography>
              </Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="caption" color="textSecondary">
                  {t("Under 60% probability")}
                </Typography>
                <Typography
                  style={{ color: CHART_COLORS.red, fontWeight: "bold" }}
                >
                  {stats.under60}
                </Typography>
              </Box>
            </Box>
          )
        },
        {
          label: "ProbabilityDistributionChart",
          component: (
            <Box position="relative" width={160} margin="auto">
              <ProbabilityDistributionChart data={data} />
            </Box>
          )
        }
      ]}
    />
  );
}

export default ProbabilityDistribution;
