import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function AccountCreate({ onNewData }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ role: "admin" });
  const [{ loading, error }, createUser] = useAxios(
    { url: "/users", method: "POST" },
    { manual: true }
  );

  const handleChange = field => event => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    createUser({ data: formData }).then(res => {
      if (res.status === 201 && res.data) {
        if (onNewData) {
          onNewData({ ...formData, _id: res.data.insertedId });
        }
      }
    });
  };

  return (
    <Paper component={Box} p={2}>
      <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
        {t("Create an account")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label={t("Display Name")}
          value={formData["displayName"] || ""}
          onChange={handleChange("displayName")}
          margin="normal"
          size="small"
          required
          variant="outlined"
          fullWidth
        />
        <TextField
          label={t("Username")}
          value={formData["username"] || ""}
          onChange={handleChange("username")}
          margin="normal"
          size="small"
          required
          variant="outlined"
          fullWidth
        />
        <TextField
          label={t("Password")}
          value={formData["password"] || ""}
          onChange={handleChange("password")}
          margin="normal"
          size="small"
          required
          variant="outlined"
          type="password"
          fullWidth
        />
        <Box pt={2} pb={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Submit
          </Button>
        </Box>
        <Typography color="error">
          {error
            ? error.response.data
              ? error.response.data.message
              : error.message
            : ""}
        </Typography>
      </form>
    </Paper>
  );
}

export default AccountCreate;
