import React from "react";
import ApiRequestChart from "../api-manager/ApiRequestChart";
import { Grid } from "@material-ui/core";
import TrainingStatistic from "./TrainingStatistic";
import TrainingStats from "./TrainingStats";
import ModelStats from "./ModelStats";
import ApiStats from "../api-manager/ApiStats";
import ProbabilityDistribution from "./ProbabilityDistribution";
import ResponseTime from "./ResponseTime";

function Dashboard() {
  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} md={12} lg={6}>
        <TrainingStats />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ModelStats />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ApiStats />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <ProbabilityDistribution />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <ResponseTime />
      </Grid>
      {/* <Grid item xs={12} md={6} lg={3}>
        <CpuLoadAvg />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <MemUsage />
      </Grid> */}
      <Grid item xs={12} md={12} lg={6}>
        <TrainingStatistic />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <ApiRequestChart />
      </Grid>
      {/* <Grid item xs={12} md={12} lg={4}>
        <GpuUsage />
      </Grid> */}
    </Grid>
  );
}

export default Dashboard;
