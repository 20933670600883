import React, { useContext } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Equalizer";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { Link, useLocation } from "react-router-dom";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import AccountIcon from "@material-ui/icons/Group";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import APIIcon from "@material-ui/icons/FlashOn";
import TestingIcon from "@material-ui/icons/DoneAll";
import LangIcon from "@material-ui/icons/Language";
import { Avatar, Chip, Divider } from "@material-ui/core";
import { AppContext } from "./AppContext";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  activeItem: {
    backgroundColor: "#ffffff22 !important"
  }
}));

export default function DrawerMenu() {
  const location = useLocation();
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const { setToken } = useContext(AppContext);
  const [{ data: currentUser }] = useAxios("/profile");
  const [{ data: classifications }] = useAxios("/classifications");

  const handleLogout = () => {
    setToken("none");
  };

  return (
    <List>
      <ListItem>
        <ListItemText
          primary={currentUser && `Hi, ${currentUser.displayName}`}
          secondary={currentUser && `@${currentUser.username}`}
          secondaryTypographyProps={{ color: "inherit" }}
        />
      </ListItem>
      <Divider
        style={{ backgroundColor: "#ffffff92", height: 2, margin: "8px 0" }}
      />
      <ListItem
        button
        component={Link}
        to="/"
        selected={location.pathname === "/"}
        classes={{
          selected: styles.activeItem
        }}
      >
        <ListItemIcon>
          <DashboardIcon htmlColor="white" />
        </ListItemIcon>
        <ListItemText primary={t("Dashboard")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/training"
        selected={
          location.pathname === "/training" ||
          location.pathname.startsWith("/annotate")
        }
        classes={{
          selected: styles.activeItem
        }}
      >
        <ListItemIcon>
          <DonutLargeIcon htmlColor="white" />
        </ListItemIcon>
        <ListItemText primary={t("Training")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/testing"
        selected={location.pathname === "/testing"}
        classes={{
          selected: styles.activeItem
        }}
      >
        <ListItemIcon>
          <TestingIcon htmlColor="white" />
        </ListItemIcon>
        <ListItemText primary={t("Testing")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/api-manager"
        selected={location.pathname === "/api-manager"}
        classes={{
          selected: styles.activeItem
        }}
      >
        <ListItemIcon>
          <APIIcon htmlColor="white" />
        </ListItemIcon>
        <ListItemText primary={t("API Manager")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/datasets"
        selected={location.pathname === "/datasets"}
        classes={{
          selected: styles.activeItem
        }}
      >
        <ListItemIcon>
          <WidgetsIcon htmlColor="white" />
        </ListItemIcon>
        <ListItemText primary={t("Datasets")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/accounts"
        selected={location.pathname === "/accounts"}
        classes={{
          selected: styles.activeItem
        }}
      >
        <ListItemIcon>
          <AccountIcon htmlColor="white" />
        </ListItemIcon>
        <ListItemText primary={t("Accounts")} />
      </ListItem>
      <Divider
        style={{ backgroundColor: "#ffffff92", height: 2, margin: "16px 0" }}
      />
      <ListItem
        button
        onClick={() =>
          i18n.changeLanguage(i18n.language.startsWith("ko") ? "en" : "ko")
        }
      >
        <ListItemIcon>
          <LangIcon htmlColor="white" />
        </ListItemIcon>
        <ListItemText
          primary={`${t("Switch to")} ${
            i18n.language.startsWith("ko") ? "English" : "Korean"
          }`}
        />
      </ListItem>
      <ListItem button onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon htmlColor="white" />
        </ListItemIcon>
        <ListItemText primary={t("Logout")} />
      </ListItem>
      <Divider
        style={{ backgroundColor: "#ffffff92", height: 2, margin: "16px 0" }}
      />
      <ListItem>
        <ListItemText
          primary={t("Trained classifications")}
          secondary={classifications?.map(({ _id, index, name }) => (
            <Chip
              key={_id}
              avatar={<Avatar>{index}</Avatar>}
              label={name}
              size="small"
              style={{
                marginRight: 6,
                marginTop: 6
              }}
            />
          ))}
          secondaryTypographyProps={{ color: "inherit" }}
        />
      </ListItem>
    </List>
  );
}
