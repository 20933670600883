import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { API_BASE, DATASET_MODES, DATASET_STATES } from "../../constants";
import { AppContext } from "../AppContext";
import TrainingConfirmDialog from "./TrainingConfirmDialog";

function ProcessItem(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [dataset, setDataset] = useState(props.data);
  const {
    _id,
    name,
    state,
    createdAt,
    createdBy,
    imageCount,
    annotatedCount,
    mode
  } = dataset;
  const [refetchCount, setRefetchCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const { eventSource } = useContext(AppContext);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    const listener = ({ data }) => {
      const json = JSON.parse(data);
      setRefetchCount(prev => prev + 1);
      setDataset(prev => ({
        ...prev,
        ...json
      }));
    };
    eventSource.addEventListener(`dataset.update.${_id}`, listener);
    return () => {
      eventSource.removeEventListener(`dataset.update.${_id}`, listener);
    };
  }, [_id, eventSource]);

  return (
    <>
      <Box
        display="flex"
        paddingY={1}
        borderTop="solid 1px lightgrey"
        alignItems="center"
      >
        <Box
          title={"Created at " + new Date(createdAt).toLocaleString()}
          height="72px"
          width="72px"
          minWidth="72px"
          borderRadius={4}
          overflow="hidden"
          border="solid 1px grey"
          mr={1}
          style={{
            backgroundImage:
              state === DATASET_STATES.pending
                ? `url('${API_BASE}/datasets/${_id}/thumbnail?v=${refetchCount}')`
                : `url('${API_BASE}/datasets/${_id}/thumbnail')`,
            backgroundSize: "cover",
            backgroundColor: "lightgrey"
          }}
        />
        <Box flexGrow={1} overflow="hidden">
          <Typography noWrap title={`${name} (${imageCount} images)`}>
            {name} ({imageCount} {t("images")})
          </Typography>
          <Typography color="textSecondary">
            {t(state)}
            {state === DATASET_STATES.annotating &&
              ` (${annotatedCount ?? 0} of ${imageCount})`}
          </Typography>
          {createdBy && (
            <Typography color="textSecondary">
              {t("Created by")} {createdBy}
            </Typography>
          )}
        </Box>
        <Box>
          {mode === DATASET_MODES.contained &&
            (state === DATASET_STATES.extracted ||
              state === DATASET_STATES.annotating) && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => history.push(`/annotate/${_id}`)}
              >
                {t("Annotate")}
              </Button>
            )}
          {state !== DATASET_STATES.training &&
            state === DATASET_STATES.annotated && (
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleOpenDialog}
              >
                {t("Train")}
              </Button>
            )}
        </Box>
      </Box>
      <TrainingConfirmDialog
        datasetId={_id}
        open={openDialog}
        onClose={handleCloseDialog}
        onStartTrain={props.onStartTrain}
      />
    </>
  );
}

export default ProcessItem;
