import {
  Box,
  Button,
  CssBaseline,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import useAxios from "axios-hooks";
import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { AppContext } from "./AppContext";

function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { setToken, isAuth } = useContext(AppContext);
  const [formData, setFormData] = useState({});
  const [{ loading, error }, login] = useAxios(
    {
      url: "/login",
      method: "POST"
    },
    { manual: true }
  );

  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (isAuth) {
      history.replace(from);
    }
  }, [isAuth, history, from]);

  const handleChange = field => event => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    login({ data: formData }).then(res => {
      if (res.status === 201) {
        setToken(res.data.access_token);
        history.replace(from);
      }
    });
  };

  return (
    <Box p={2}>
      <CssBaseline />
      <Paper
        component={Box}
        margin="auto"
        mt="10%"
        width="100%"
        maxWidth="300px"
        p={2}
      >
        <Typography variant="h4" align="center" color="primary">
          <strong>{t('CAD DETECTOR')}</strong>
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary">
          {t('Administrators Site')}
        </Typography>
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          mt={2}
          textAlign="center"
        >
          <TextField
            label={t("Username")}
            variant="outlined"
            margin="normal"
            size="small"
            value={formData["username"] || ""}
            onChange={handleChange("username")}
            fullWidth
            required
          />
          <TextField
            label={t("Password")}
            variant="outlined"
            margin="normal"
            size="small"
            type="password"
            value={formData["password"] || ""}
            onChange={handleChange("password")}
            fullWidth
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: 24 }}
            disabled={loading}
          >
            Login
          </Button>
          <Box pt={2} visibility={error ? "visible" : "hidden"}>
            <Typography variant="caption" color="error">
              {error &&
                ((error.response && error.response.status === 401)
                  ? t("Username or pasword is incorrect")
                  : error.message)}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default Login;
