import React, { useState, useEffect } from "react";
import NumberBox from "../NumberBox";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import GestureIcon from "@material-ui/icons/Gesture";
import ImageIcon from "@material-ui/icons/Image";
import { useTranslation } from "react-i18next";

const color = {
  processTime: "#2196f3",
  avgProb: "#ff9800",
  avgDuration: "#4caf50",
  totalBp: "#f44336",
  totalImg: "#9c27b0"
};
function TestResultStats({ testStat, images }) {
  const [stat, setStat] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setStat(testStat);
  }, [testStat]);

  return (
    <NumberBox
      title={t("Detection Stats")}
      itemPerRow={2}
      items={[
        {
          label: t("Average Probability"),
          value: stat && stat.avgProb && `${(stat.avgProb * 100).toFixed(2)}%`,
          color: color.avgProb,
          icon: <AllInclusiveIcon />
        },
        {
          label: t("Average Process Time"),
          value:
            stat &&
            stat.avgProcessTime &&
            `${stat.avgProcessTime.toFixed(0)}ms`,
          color: color.avgDuration,
          icon: <QueryBuilderIcon />
        },
        {
          label: t("Total Detected Blueprints"),
          value: stat && stat.allDetected,
          color: color.totalBp,
          icon: <GestureIcon />
        },
        {
          label: t("Total Selected Images"),
          value: images.length || 0,
          color: color.totalImg,
          icon: <ImageIcon />
        }
      ]}
    />
  );
}

export default TestResultStats;
