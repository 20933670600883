import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import ChartWrapper from "../ChartWrapper";
import { CHART_COLORS } from "../../constants";
import DateRangeSelect from "../DateRangeSelect";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";

function ApiRequestChart() {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState([]);
  const [{ data }] = useAxios({
    url: "/detect-results/count-by-date",
    params: {
      from: dateRange[0],
      to: dateRange[1]
    }
  });

  const datasets = [
    {
      label: t("# Requests"),
      data: [],
      fill: false,
      backgroundColor: CHART_COLORS.purple,
      borderColor: CHART_COLORS.purple,
      yAxisID: "y0",
      order: 0
    },
    {
      type: "bar",
      label: t("Avg Response Time (ms)"),
      data: [],
      fill: false,
      backgroundColor: CHART_COLORS.blue,
      borderColor: CHART_COLORS.blue,
      yAxisID: "y1",
      maxBarThickness: 24,
      borderWidth: 3,
      order: 1
    }
  ];

  if (data) {
    for (let index = data.length - 1; index >= 0; index--) {
      const { _id, count, avgDuration } = data[index];
      datasets[0].data.push({
        x: new Date(_id),
        y: count
      });
      datasets[1].data.push({
        x: new Date(_id),
        y: avgDuration
      });
    }
  }

  return (
    <ChartWrapper
      title={t("API Request Statistic")}
      actions={<DateRangeSelect onChange={setDateRange} />}
    >
      <Line
        data={{ datasets }}
        options={{
          scales: {
            xAxis: {
              type: "time",
              time: {
                unit: "day"
              }
            },
            y0: {
              min: 0,
              suggestedMax: 10
            },
            y1: {
              display: false,
              min: 0,
              suggestedMax: 10000
            }
          },
          plugins: {
            legend: {
              position: "bottom"
            }
          }
        }}
      />
    </ChartWrapper>
  );
}

export default ApiRequestChart;
