import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from "@material-ui/core";
import useAxios from "axios-hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { API_BASE, CHART_COLORS } from "../../constants";
import MoreIcon from "@material-ui/icons/MoreVert";
import { downloadFile } from "../../helpers/downloadFile";
import { useSnackbar } from "notistack";

function ModelItem({ data, onChanges }) {
  const { t } = useTranslation();
  const {
    _id,
    createdAt,
    createdBy,
    accuracy,
    finishedIter,
    loss,
    active,
    name,
    cls = [],
    clsAccuracy = []
  } = data;
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [{ loading: activating, error: activateError }, doActivate] = useAxios(
    {
      url: `models/${_id}/active`,
      method: "PATCH"
    },
    { manual: true }
  );
  const [{ loading: exporting, error: exportError }, doExport] = useAxios(
    { url: `/exports/${_id}`, method: "POST" },
    { manual: true }
  );
  const [{ loading: deleting, error: deleteError }, doDelete] = useAxios(
    {
      url: `models/${_id}`,
      method: "DELETE"
    },
    { manual: true }
  );

  const loading = activating || exporting || deleting;
  const error = activateError || exportError || deleteError;

  const handleActivate = mode => {
    doActivate({ params: { mode } }).then(res => {
      if (res.status === 200) {
        onChanges();
        setOpenDialog(false);
        enqueueSnackbar(t("The model is activated"), { variant: "success" });
      }
    });
  };

  const handleExport = () => {
    doExport().then(res => {
      if (res.status === 201 && res.data && res.data.file) {
        downloadFile(`${API_BASE}/exports/${res.data.file}`, res.data.file);
        setOpenDialog(false);
      }
    });
  };

  const handleDelete = () => {
    doDelete().then(res => {
      if (res.status === 200) {
        onChanges();
        setOpenDialog(false);
        enqueueSnackbar(t("The model is deleted"), { variant: "success" });
      }
    });
  };

  const contentMap = {
    activate1: {
      title: t("Activate this model at 1st detection?"),
      content: t(
        "Use this model at 1st detection for detection API from now on"
      ),
      action: () => handleActivate(1)
    },
    activate2: {
      title: t("Activate this model at 2nd detection?"),
      content: t(
        "Use this model at 2nd detection for detection API from now on"
      ),
      action: () => handleActivate(2)
    },
    deactivate: {
      title: t("Deactivate this model?"),
      content: t("Stop using this model for detection API."),
      action: () => handleActivate(0)
    },
    export: {
      title: t("Export this model?"),
      content: t("Export and download this model, it will take a while."),
      action: handleExport
    },
    delete: {
      title: t("Delete this model?"),
      content: t("There are no way to undone!"),
      action: handleDelete
    }
  };

  return (
    <ListItem selected={active}>
      <ListItemAvatar>
        <Avatar
          title={t("Accuracy")}
          style={{
            backgroundColor: active ? CHART_COLORS.purple : CHART_COLORS.green
          }}
        >
          <small>{(accuracy * 1).toFixed(0)}%</small>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant="caption" display="block" color="textSecondary">
              {new Date(createdAt).toLocaleString()}
              {" - "}
              {createdBy}
            </Typography>
            <Typography>
              {name || "?"}
              {!!active && (
                <strong style={{ color: CHART_COLORS.purple }}>
                  {" "}
                  ({t("in use")} at {active})
                </strong>
              )}
            </Typography>
          </>
        }
        secondary={
          <>
            {`${loss} ${t("loss")}, ${finishedIter} ${t("iterations")}, `}
            {cls?.map(
              (clname, index) =>
                `${clname} (${(clsAccuracy && clsAccuracy[index]) || 0}%), `
            )}
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          {!active && (
            <MenuItem
              component="div"
              onClick={() => setOpenDialog("activate1")}
            >
              Activate at 1st
            </MenuItem>
          )}
          {!active && (
            <MenuItem
              component="div"
              onClick={() => setOpenDialog("activate2")}
            >
              Activate at 2nd
            </MenuItem>
          )}
          {!!active && (
            <MenuItem
              component="div"
              onClick={() => setOpenDialog("deactivate")}
            >
              Deactivate
            </MenuItem>
          )}
          <MenuItem component="div" onClick={() => setOpenDialog("export")}>
            Export
          </MenuItem>
          <MenuItem
            disabled={active}
            component="div"
            onClick={() => setOpenDialog("delete")}
          >
            Delete
          </MenuItem>
        </Popover>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>
            {openDialog && contentMap[openDialog].title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {openDialog && contentMap[openDialog].content}
            </DialogContentText>
            {error && (
              <DialogContentText color="error">
                {error.message}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} disabled={loading}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={openDialog && contentMap[openDialog].action}
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default ModelItem;
