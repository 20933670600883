import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ProcessEmpty from "./ProcessEmpty";
import ProcessError from "./ProcessError";
import ProcessItem from "./ProcessItem";
import ProcessLoading from "./ProcessLoading";

function ProcessPanel({ list, isEmpty, isLoading, error, onStartTrain }) {
  const { t } = useTranslation();
  const renderList = () =>
    list.map(item => (
      <ProcessItem key={item._id} data={item} onStartTrain={onStartTrain} />
    ));

  return (
    <Paper component={Box} elevation={1} p={2}>
      <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
        {t("Work in progress")}
      </Typography>
      <Box height={12} />
      {isEmpty && <ProcessEmpty />}
      {isLoading && <ProcessLoading />}
      {error && <ProcessError error={error} />}
      {list && renderList()}
    </Paper>
  );
}

export default ProcessPanel;
