import { Grid } from "@material-ui/core";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import AccountCreate from "./AccountCreate";
import AccountItem from "./AccountItem";

function AccountList() {
  const [users, setUsers] = useState([]);
  const [{ data }] = useAxios("/users?skip=0&limit=0");
  const [{ data: currentUser }] = useAxios("/profile");

  useEffect(() => {
    if (!data) return;
    setUsers(data);
  }, [data]);

  const handleNewData = newData => {
    setUsers([newData, ...users]);
  };

  return (
    <Grid container spacing={2}>
      {currentUser && currentUser.role === "superadmin" && (
        <Grid item xs={12} lg={4}>
          <AccountCreate onNewData={handleNewData} />
        </Grid>
      )}
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          {users.map(item => (
            <Grid item key={item._id} xs>
              <AccountItem data={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AccountList;
