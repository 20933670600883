import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import React, { useState } from "react";
import StopIcon from "@material-ui/icons/Stop";
import useAxios from "axios-hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function StopTrainingButton({ trainingJobId }) {
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [{ loading: stopLoading, error }, stopTraining] = useAxios(
    {
      url: `training-jobs/${trainingJobId}/stop`,
      method: "POST"
    },
    { manual: true }
  );

  const handleStopTraining = () => {
    stopTraining()
      .then(res => {
        if (res.status === 201) {
          enqueueSnackbar(t("The training is stopped"), { variant: "success" });
          setOpenDialog(false);
        } else {
          enqueueSnackbar(t("Failed to stop the training"), {
            variant: "error"
          });
        }
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar(t("Failed to stop the training"), { variant: "error" });
      });
  };

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setOpenDialog(true)}
        startIcon={stopLoading ? <CircularProgress size={24} /> : <StopIcon />}
        disabled={stopLoading}
      >
        Stop
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{t("Are you sure to stop the training?")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("The training will be aborted.")}
          </DialogContentText>
          <DialogContentText>
            {t("Current training result will not be applied to the model.")}
          </DialogContentText>
          <DialogContentText>
            {t(
              "If you want to train again, it will restart from the beginning."
            )}
          </DialogContentText>
          <DialogContentText color="error">
            {(error && error.message) ?? error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>{t("Cancel")}</Button>
          <Button
            onClick={handleStopTraining}
            color="secondary"
            disabled={stopLoading}
            startIcon={stopLoading && <CircularProgress size={24} />}
          >
            {t("Stop")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default StopTrainingButton;
