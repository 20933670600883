import React from "react";
import { Box, Typography, IconButton, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { green, red } from "@material-ui/core/colors";
import useAxios from "axios-hooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    alignItems: "center"
  },
  check: {
    color: green[500]
  },
  close: {
    color: red[500]
  },
  boxButton: {
    display: "flex",
    marginLeft: 10
  },
  closeIconButton: {
    marginLeft: 5
  }
}));

function SaveSelectedDataset({
  handleOpenSaveSelectedDataset,
  resultData,
  selectedDataset,
  refetchDataset
}) {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [, AddImageToDataset] = useAxios(
    { url: `/datasets/${selectedDataset.id}/images`, method: "POST" },
    { manual: true }
  );
  const { t } = useTranslation();

  const handleSubmit = e => {
    e.preventDefault();

    const formDataImgAdd = new FormData();
    formDataImgAdd.append("file", resultData.value);

    AddImageToDataset({
      data: formDataImgAdd
    }).then(res => {
      if (res.status === 201) {
        enqueueSnackbar(t(`Image is added to dataset`), {
          variant: "success"
        });
        handleOpenSaveSelectedDataset();
        refetchDataset();
      } else {
        enqueueSnackbar(t("Cannot save image to new dataset!"), {
          variant: "error"
        });
        return;
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={styles.box}>
        <Typography
          style={{
            textTransform: "none",
            textAlign: "center"
          }}
        >
          {t("Save the image to")} {selectedDataset.name}?
        </Typography>
        <Box className={styles.boxButton}>
          <IconButton aria-label="Save" type="submit">
            <CheckIcon className={styles.check} />
          </IconButton>
          <IconButton
            aria-label="Close"
            className={styles.closeIconButton}
            onClick={handleOpenSaveSelectedDataset}
          >
            <CloseIcon className={styles.close} />
          </IconButton>
        </Box>
      </Box>
    </form>
  );
}

export default SaveSelectedDataset;
