import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import DrawerMenu from "./DrawerMenu";
import { Box, IconButton, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/HomeRounded";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { APP_VERSION } from "../constants";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundImage: `url('/SideMenuBG-Image.png')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: "100vh",
    position: "relative"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: `rgba(0, 0, 0, 0.6)`,
    color: "white"
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  }
}));

export default function MainLayout({ children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
      >
        <Box className={classes.toolbar}>
          <IconButton color="inherit" onClick={() => history.push("/")}>
            <HomeIcon />
          </IconButton>
        </Box>
        <Divider style={{ backgroundColor: "#ffffff92", height: 2 }} />
        <DrawerMenu />
        <Typography variant="caption" style={{ position: "fixed", bottom: 8, left: 18 }}>
          Version {APP_VERSION}
        </Typography>
      </Drawer>
      <main className={classes.content}>
        <Box pb={3} display="flex" justifyContent="center" alignItems="center">
          <Box
            component="img"
            height={32}
            width={32}
            src="/logo192.png"
            alt=""
            mr={2}
          />
          <Typography variant="h5" style={{ letterSpacing: 2 }}>
            <strong>{t("CAD DETECTOR")}</strong>
          </Typography>
        </Box>
        {children}
      </main>
    </div>
  );
}
