import { Box, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TRAIN_STATES } from "../../constants";
import useServerEvent from "../../helpers/useEvent";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";

function AugmentStatus({ trainingJob }) {
  const { t } = useTranslation();
  const { _id, augmentProcessed, augmentTotal, state: initState } = trainingJob;
  const [{ processed, total }, setData] = useServerEvent(
    `training_job.augment_output.${_id}`,
    {
      total: 100,
      processed: 0
    }
  );
  const [{ state }, setState] = useServerEvent(`training_job.update.${_id}`, {
    state: initState
  });

  useEffect(() => {
    setState({ state: initState });
  }, [initState, setState]);

  useEffect(() => {
    if (!(augmentTotal && augmentProcessed)) return;
    setData({
      total: augmentTotal,
      processed: augmentProcessed
    });
  }, [augmentTotal, augmentProcessed, setData]);

  return (
    <Paper
      component={Box}
      p={2}
      elevation={state === TRAIN_STATES.augmenting ? 6 : 1}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {t("Augmenting data")}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t("Processed")} {processed} / {total}
          </Typography>
        </Box>
        <CircularProgressWithLabel value={(100 * processed) / total} />
      </Box>
    </Paper>
  );
}

export default AugmentStatus;
