import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { memo } from "react";
import { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "../../constants";
import { AppContext } from "../AppContext";

function TotalNumber({ data }) {
  const { t } = useTranslation();
  const [total, setTotal] = useState();
  const { eventSource } = useContext(AppContext);

  useEffect(() => {
    if (data) {
      setTotal(Object.values(data).reduce((a, b) => a + b, 0));
    }
  }, [data]);

  useEffect(() => {
    const listener = () => {
      setTotal(prev => prev + 1);
    };
    eventSource.addEventListener(`detect_image`, listener);
    return () => {
      eventSource.removeEventListener(`detect_image`, listener);
    };
  }, [eventSource]);

  return (
    <Typography
      align="center"
      variant="caption"
      color="textSecondary"
      display="block"
    >
      <strong>{total}</strong>
      <br />
      {t("total")}
    </Typography>
  );
}

function ResponseTimeChart({ data }) {
  const { t } = useTranslation();
  const ref = useRef();
  const labels = [
    t("Under 1 second"),
    t("1 to 2 seconds"),
    t("2 to 5 seconds"),
    t("Over 5 seconds")
  ];
  const datasets = [
    {
      label: "Dataset 1",
      data: [data?.under1s, data?.over1s, data?.over2s, data?.over5s],
      backgroundColor: [
        CHART_COLORS.green,
        CHART_COLORS.blue,
        CHART_COLORS.orange,
        CHART_COLORS.red
      ]
    }
  ];

  const { eventSource } = useContext(AppContext);

  useEffect(() => {
    const listener = ({ data }) => {
      const { duration } = JSON.parse(data);
      if (duration < 1000) {
        ref.current.data.datasets[0].data[0] += 1;
      } else if (duration < 2000) {
        ref.current.data.datasets[0].data[1] += 1;
      } else if (duration < 5000) {
        ref.current.data.datasets[0].data[2] += 1;
      } else {
        ref.current.data.datasets[0].data[3] += 1;
      }
      ref.current.update();
    };
    eventSource.addEventListener(`detect_image`, listener);
    return () => {
      eventSource.removeEventListener(`detect_image`, listener);
    };
  }, [eventSource]);

  return (
    <Box position="relative" mt={-3}>
      <Box position="absolute" top="36%" left="42%">
        <TotalNumber data={data} />
      </Box>
      <Doughnut
        ref={ref}
        data={{ labels, datasets }}
        width={"100%"}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          }
        }}
      />
    </Box>
  );
}

export default memo(ResponseTimeChart);
