import React, { memo, useContext, useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { AppContext } from "../AppContext";

function TrainingOutputChart({ trainingJobId, data }) {
  const ref = useRef();
  const { t } = useTranslation();
  const { eventSource } = useContext(AppContext);
  const [datasets, setDatasets] = useState([
    {
      label: t("Accuracy"),
      data: [],
      fill: false,
      backgroundColor: "green",
      borderColor: "green",
      yAxisID: "y1"
    },
    {
      label: t("Loss"),
      data: [],
      fill: false,
      backgroundColor: "orangered",
      borderColor: "transparent",
      yAxisID: "y0"
    }
  ]);

  useEffect(() => {
    const listener = ({ data }) => {
      const { iteration, accuracy, loss } = JSON.parse(data);
      if (accuracy > 0) {
        ref.current.data.datasets[0].data.push({ x: iteration, y: accuracy });
      }
      ref.current.data.datasets[1].data.push({ x: iteration, y: loss });
      ref.current.update();
    };
    eventSource.addEventListener(
      `training_job.train_output.${trainingJobId}`,
      listener
    );
    return () => {
      eventSource.removeEventListener(
        `training_job.train_output.${trainingJobId}`,
        listener
      );
    };
  }, [trainingJobId, eventSource]);

  useEffect(() => {
    setDatasets(prev => {
      const tmp = [...prev];
      for (let i = data.length - 1; i >= 0; i--) {
        const { iteration, accuracy, loss } = data[i];
        if (accuracy > 0) {
          tmp[0].data.push({ x: iteration, y: accuracy });
        }
        tmp[1].data.push({ x: iteration, y: loss });
      }
      return tmp;
    });
  }, [data]);

  return (
    <Line
      ref={ref}
      data={{ datasets }}
      options={{
        scales: {
          xAxis: {
            type: "linear",
            ticks: {
              stepSize: 1
            },
            title: {
              display: true,
              text: "iteration",
              align: "end"
            }
          },
          y0: {
            type: "linear"
          },
          y1: {
            type: "linear",
            max: 100,
            min: 0,
            display: false
          }
        },
        plugins: {
          legend: {
            display: true,
            position: "bottom"
          }
        }
      }}
    />
  );
}

export default memo(TrainingOutputChart);
