import React, { useState, useEffect, memo } from "react";
import { Box, Button, ButtonGroup, Paper, Typography } from "@material-ui/core";
import ImageBoundingBox from "react-bounding-box";
import SaveNewDataset from "./SaveNewDataset";
import SaveSelectedDataset from "./SaveSelectedDataset";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function TestResult({ currentResult, selectedDataset, refetchDataset }) {
  const { t } = useTranslation();
  const [resultData, setResultData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [openSaveNewDataset, setOpenSaveNewDataset] = useState(false);
  const [openSaveSelectedDataset, setOpenSaveSelectedDataset] = useState(false);
  const handleOpenSaveNewDataset = () => {
    setOpenSaveNewDataset(!openSaveNewDataset);
  };
  const handleOpenSaveSelectedDataset = () => {
    console.log(selectedDataset);
    if (!selectedDataset) {
      enqueueSnackbar(t("Please select one dataset in Test Dataset section!"), {
        variant: "warning"
      });
      return;
    }
    setOpenSaveSelectedDataset(!openSaveSelectedDataset);
  };
  useEffect(() => {
    if (!currentResult) return setResultData(null);
    const extractResult = [];
    const data = currentResult;

    for (var i = 0; i < data.results.length; i++) {
      const tmp = [];
      if (data.results[i].box) {
        tmp.push(
          parseInt(data.results[i].box["x"] - data.results[i].box["w"] / 2)
        );
        tmp.push(
          parseInt(data.results[i].box["y"] - data.results[i].box["h"] / 2)
        );
        tmp.push(data.results[i].box["w"]);
        tmp.push(data.results[i].box["h"]);
        extractResult.push({
          coord: tmp,
          label: `${data.results[i].name} (${(
            data.results[i].prob * 100
          ).toFixed(2)}%)`
        });
      }
    }

    setResultData({ ...data, results: extractResult });
  }, [currentResult]);
  return (
    <Paper
      component={Box}
      p={2}
      style={{ maxHeight: "88vh", minHeight: "18vh" }}
    >
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography
            style={{
              textTransform: "uppercase",
              fontWeight: "bold"
            }}
          >
            {t("Result")}
          </Typography>
        </Box>
        {openSaveNewDataset && (
          <SaveNewDataset
            handleOpenSaveNewDataset={handleOpenSaveNewDataset}
            resultData={resultData}
            refetchDataset={refetchDataset}
          />
        )}
        {openSaveSelectedDataset && (
          <SaveSelectedDataset
            handleOpenSaveSelectedDataset={handleOpenSaveSelectedDataset}
            resultData={resultData}
            selectedDataset={selectedDataset}
            refetchDataset={refetchDataset}
          />
        )}
        {resultData && !openSaveSelectedDataset && !openSaveNewDataset && (
          <ButtonGroup>
            <Button
              type="button"
              // variant="contained"
              // style={{ textTransform: "none" }}
              color="primary"
              onClick={handleOpenSaveNewDataset}
            >
              {t("Save in new dataset")}
            </Button>

            <Button
              type="button"
              // variant="contained"
              // style={{ textTransform: "none" }}
              color="primary"
              onClick={handleOpenSaveSelectedDataset}
            >
              {t("Save in selected dataset")}
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <hr />
      {!resultData && (
        <Typography
          variant="body2"
          display="block"
          style={{ textAlign: "center" }}
          gutterBottom
          color="textSecondary"
        >
          {t(
            "No result selected. Please select a detected image in image list to view the result."
          )}
        </Typography>
      )}
      {resultData && (
        <>
          <Typography
            variant="body2"
            display="block"
            style={{ textAlign: "center" }}
            gutterBottom
            color="textSecondary"
          >
            {resultData.results?.map(result => result.label)?.join(", ")}
            {!resultData.results?.length && "No blueprint found!"}
          </Typography>
          <Box
            key={resultData.url}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 12,
              transition: "ease-in 400ms all"
            }}
          >
            <ImageBoundingBox
              image={resultData.url}
              boxes={resultData.results}
              options={{
                colors: {
                  normal: "red",
                  selected: "blue",
                  unselected: "yellow"
                },
                style: {
                  maxWidth: "100%",
                  maxHeight: "40vh"
                }
              }}
            />
          </Box>
        </>
      )}
    </Paper>
  );
}

export default memo(TestResult);
