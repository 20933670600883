import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { API_BASE, TRAIN_STATES } from "../../constants";
import { convert2HHMMSS } from "../../helpers/converter";
import { AppContext } from "../AppContext";
import NumberBox from "./NumberBox";
import TrainingConfirmDialog from "./TrainingConfirmDialog";
import StopTrainingButton from "./StopTrainingButton";
import { useTranslation } from "react-i18next";

function TrainingJob({ data, onStartTrain }) {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [detail, setDetail] = useState();
  const {
    avgAccuracy,
    avgLoss,
    config,
    createdAt,
    createdBy,
    stoppedBy,
    datasetId,
    finishedAt,
    state,
    trainingTime,
    cpus,
    memory,
    error,
    stopped,
    containerId,
    brokenImages,
    cls = [],
    clsAccuracy = [],
    _id
  } = detail || {};
  const { eventSource } = useContext(AppContext);
  const [
    { data: dataset, loading: datasetLoading, error: datasetError }
  ] = useAxios(`/datasets/${datasetId}`, { manual: !datasetId });

  useEffect(() => setDetail(data), [data]);

  useEffect(() => {
    const listener = ({ data }) => {
      const json = JSON.parse(data);
      setDetail(prev => ({
        ...prev,
        ...json
      }));
    };
    eventSource.addEventListener(`training_job.update.${_id}`, listener);
    return () => {
      eventSource.removeEventListener(`training_job.update.${_id}`, listener);
    };
  }, [_id, eventSource]);

  return (
    <Paper
      component={Box}
      p={2}
      elevation={state === TRAIN_STATES.compiling ? 6 : 1}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography
                style={{ textTransform: "uppercase", fontWeight: "bold" }}
              >
                {t("Last trained: ")}
                {datasetLoading && "Checking..."}
                {datasetError && "???"}
                {dataset && dataset.name}
              </Typography>
              {datasetError && (
                <Typography variant="caption" color="error">
                  {t("Error:")} {datasetError}
                </Typography>
              )}
              <Typography variant="caption" color="textSecondary">
                {new Date(createdAt).toLocaleString()}
              </Typography>
              {finishedAt && (
                <Typography variant="caption" color="textSecondary">
                  {" -> "}
                  {new Date(finishedAt).toLocaleString()}
                </Typography>
              )}
              <br />
              {createdBy && (
                <Typography variant="caption" color="textSecondary">
                  {t("Created by")} {createdBy}
                </Typography>
              )}
              {stoppedBy && (
                <Typography variant="caption" color="textSecondary">
                  {" | "}
                  {t("Stopped by")} {stoppedBy}
                </Typography>
              )}
              <br />
              <Typography variant="caption" color="textSecondary">
                {t("Including classifications:")}{" "}
                <strong>
                  {cls?.map(
                    (clname, index) =>
                      `${clname} (${clsAccuracy[index] ?? 0}%), `
                  )}
                </strong>
              </Typography>
            </Box>
            <Box>
              {containerId &&
                !error &&
                !stopped &&
                state !== TRAIN_STATES.finish && (
                  <StopTrainingButton trainingJobId={_id} />
                )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Chip
            label={t(state)}
            size="small"
            component={Box}
            mr={1}
            mb={1}
            color="primary"
          />
          {config && config.max_batches && (
            <Chip
              label={`${config.max_batches} ${t("iterations")}`}
              size="small"
              component={Box}
              mr={1}
              mb={1}
            />
          )}
          {config && config.learning_rate && (
            <Chip
              label={`${config.learning_rate} ${t("learning rate")}`}
              size="small"
              component={Box}
              mr={1}
              mb={1}
            />
          )}
          <Chip
            label={`${cpus} CPUs`}
            size="small"
            component={Box}
            mr={1}
            mb={1}
          />
          <Chip
            label={`${memory}G Memory`}
            size="small"
            component={Box}
            mr={1}
            mb={1}
          />
          {dataset && dataset.imageCount && (
            <Chip
              label={`${dataset.imageCount} ${t("images")}`}
              size="small"
              component={Box}
              mr={1}
              mb={1}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <NumberBox
            title={t("Average Accuracy")}
            value={avgAccuracy > 0 ? `${avgAccuracy.toFixed(2)}%` : "(?)"}
            color="green"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <NumberBox
            title={t("Best Accuracy")}
            value={clsAccuracy?.length && `${Math.max(...clsAccuracy)}%`}
            color="darkblue"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <NumberBox
            title={t("Average Loss")}
            value={avgLoss && (!isNaN(+avgLoss) ? avgLoss.toFixed(2) : avgLoss)}
            color="orangered"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <NumberBox
            title={t("Total Trained Time")}
            value={trainingTime && convert2HHMMSS(trainingTime)}
            color="orange"
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={!!error || !!stopped || !!brokenImages}>
            <Box>
              <Box maxHeight={"100px"} overflow="auto" border={"solid 1px red"}>
                {error && (
                  <Typography color="error">
                    {t("Error:")} {error}
                  </Typography>
                )}
                {brokenImages &&
                  brokenImages.map(image => (
                    <Typography color="error">
                      <strong>{t("Broken image: ")}</strong>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${API_BASE}/datasets/${
                          image.split("_")[0]
                        }/images/${image}`}
                      >
                        {image}
                      </a>
                      {" (skipped)"}
                    </Typography>
                  ))}
              </Box>
              {(!!error || !!stopped) && (
                <Button
                  onClick={() => setOpenDialog(true)}
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ marginTop: 12 }}
                  disabled={!dataset}
                >
                  {t("Train again")}
                </Button>
              )}
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {!datasetLoading &&
                  !dataset &&
                  "The dataset is unavailable or have been deleted!"}
              </Typography>
            </Box>
          </Collapse>
          <TrainingConfirmDialog
            datasetId={datasetId}
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            onStartTrain={onStartTrain}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default TrainingJob;
