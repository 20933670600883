import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography
} from "@material-ui/core";
import useAxios from "axios-hooks";
import React, { useState } from "react";
import ImportIcon from "@material-ui/icons/Publish";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function ImportModelButton({ onCreated }) {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [{ loading, error }, doImport] = useAxios(
    { url: "/import", method: "POST" },
    { manual: true }
  );
  const [progress, setProgress] = useState();

  const handleUploadProgress = ({ lengthComputable, loaded, total }) => {
    if (lengthComputable) {
      setProgress((loaded / total) * 100);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    doImport({ data: formData, onUploadProgress: handleUploadProgress }).then(
      res => {
        if (res.status === 201) {
          enqueueSnackbar(t("Model is imported successfully"), {
            variant: "success"
          });
          onCreated();
          setOpenDialog(false);
        }
      }
    );
  };

  return (
    <>
      <Button onClick={() => setOpenDialog(true)} startIcon={<ImportIcon />}>
        {t("Import Model")}
      </Button>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t("Import a new model")}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="name"
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{t("Name")}</InputAdornment>
                )
              }}
              placeholder={t("What is this model for?")}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="finishedIter"
              type="number"
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {t("Finished Iterations")}
                  </InputAdornment>
                ),
                inputProps: { min: 0 }
              }}
              placeholder={t("How many iterations this model have been done?")}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="accuracy"
              margin="normal"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {t("Current Accuracy")}
                  </InputAdornment>
                ),
                inputProps: { min: 1, max: 100, step: 1 }
              }}
              placeholder={t("A number between 1 and 100")}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="loss"
              margin="normal"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {t("Current Loss")}
                  </InputAdornment>
                ),
                inputProps: { min: 0.0, max: 1.0, step: 0.01 }
              }}
              placeholder={t("A number between 0.00 and 1.00")}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="weight"
              margin="normal"
              type="file"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {t("Weight File")}
                  </InputAdornment>
                ),
                inputProps: {
                  accept: ".weights"
                }
              }}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="config"
              margin="normal"
              type="file"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {t("Config File")}
                  </InputAdornment>
                ),
                inputProps: {
                  accept: ".cfg"
                }
              }}
              required
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="names"
              margin="normal"
              type="file"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {t("Name File")}
                  </InputAdornment>
                ),
                inputProps: {
                  accept: ".names"
                }
              }}
              required
            />
            {error && (
              <Typography color="error">
                {t("Error:")} {error.message ?? error}
              </Typography>
            )}
            <Collapse in={loading}>
              <Box pt={2} display="flex" alignItems="center">
                <Box flexGrow={1} mr={1}>
                  <LinearProgress
                    variant={progress ? "determinate" : "indeterminate"}
                    value={progress}
                  />
                </Box>
                {progress && (
                  <Typography variant="caption" color="textSecondary">
                    {progress.toFixed(2)}%
                  </Typography>
                )}
              </Box>
            </Collapse>
            <Box my={4}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {t("Submit")}
              </Button>
              <Button onClick={() => setOpenDialog(false)} disabled={loading}>
                {t("Cancel")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ImportModelButton;
